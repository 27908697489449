import React from 'react'

import CloudPhone from '../../components/CloudPhone/CallsMain'

export default function AppCloudPhone(){
    return(
        <>
        <CloudPhone />
        </>
    )
}