
import { createSlice } from "@reduxjs/toolkit";
import { useSnackbar } from 'notistack';
// utils
import axios from '../../../utils/axios';

//
import { dispatch } from '../../store';

import {showSnackbar} from '../snackbar'


// ----------------------------------------------------------------------


const initialState= {
    isLoading: false,
    isCreateLoading: false,
    isUpdateLoading: false,
    error: null,
    voicemailData: {},
    voiceMailCreateResponse: {},
    voiceMailUpdateResponse: {},
    voicemailDataByIdResponse: {},
    voicemailRecordsResponse: {}
}

const voiceMailSlice = createSlice({
    name: 'voiceMail',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // START LOADING
        startCreateLoading(state) {
            state.isCreateLoading = true;
        },


      // START LOADING
      startUpdateLoading(state) {
        state.isUpdateLoading = true;
    },



        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            
        },
        
        // GET NUMBER SSUCCESS
        getVoiceMailSuccess(state, action) {
          state.voicemailData = action.payload;
          state.isLoading = false;
          
          
        },

        // GET NUMBER SSUCCESS
        postVoiceMailSuccess(state, action) {
          state.voiceMailCreateResponse = action.payload;
          state.isCreateLoading = false;
          
          
        },

         // GET NUMBER SSUCCESS
         updateVoiceMailSuccess(state, action) {
          state.voiceMailUpdateResponse = action.payload;
          state.isUpdateLoading = false;
          
          
        },

        // GET NUMBER SSUCCESS
        getByIdSuccess(state, action) {
          state.voicemailDataByIdResponse = action.payload;
          state.isLoading = false;
          
          
        },

         // GET NUMBER SSUCCESS
         getRecordsSuccess(state, action) {
          state.voicemailRecordsResponse = action.payload;
          state.isLoading = false;
          
          
        },



    }
})



export const selectVoiceMails = (state) => state.voiceMail;

export default voiceMailSlice.reducer

// Actions

export function getVoiceMailBox(athid, offsetCount, rowsPerPage) {

   
    return async () => {
      dispatch(voiceMailSlice.actions.startLoading());
      try {
        const response = await axios.get(`/voiceMail/getAll?AuthId=${athid}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`);
          dispatch(voiceMailSlice.actions.getVoiceMailSuccess(response.data));

          console.log('voice mail response', response.data)
 
      } catch (error) {
        dispatch(voiceMailSlice.actions.hasError(error));
        
      }
    };
  }

  export function getVoiceMailById(id) {

   
    return async () => {
      dispatch(voiceMailSlice.actions.startLoading());
      try {
        const response = await axios.get(`/voiceMail/get/${id}`);
          dispatch(voiceMailSlice.actions.getByIdSuccess(response.data));

          console.log('voice mail response', response.data)
 
      } catch (error) {
        dispatch(voiceMailSlice.actions.hasError(error));
        
      }
    };
  }

  export function getVoiceMailRecords(name, authid, offsetCount, rowsPerPage) {

   
    return async () => {
      dispatch(voiceMailSlice.actions.startLoading());
      try {
        const response = await axios.get(`/voiceMail/records?AccountSid=${authid}&sort=-_id&VoiceMailBoxName=${name}&limit=${rowsPerPage}&offset=${offsetCount}`);
          dispatch(voiceMailSlice.actions.getRecordsSuccess(response.data));

          console.log('voice mail records response', response.data)
 
      } catch (error) {
        dispatch(voiceMailSlice.actions.hasError(error));
        
      }
    };
  }

  export function getVoiceMailRecordsSearch(name, authid, offsetCount, rowsPerPage, caller, startDate, endDate) {

   
    return async () => {
      dispatch(voiceMailSlice.actions.startLoading());
      try {
        const response = await axios.get(`/voiceMail/records?Caller=${caller}&startDate=${startDate}&endDate=${endDate}&AccountSid=${authid}&sort=-_id&VoiceMailBoxName=${name}&limit=${rowsPerPage}&offset=${offsetCount}`);
          dispatch(voiceMailSlice.actions.getRecordsSuccess(response.data));

          console.log('voice mail records response', response.data)
 
      } catch (error) {
        dispatch(voiceMailSlice.actions.hasError(error));
        
      }
    };
  }



  export function createVoiceMail(mailName, authId) {

    const data = {
      "Name" : mailName,
      "AuthId" : authId
    }
    return async () => {
      dispatch(voiceMailSlice.actions.startCreateLoading());
      try {
        const response = await axios.post(`/voiceMail/create`, data);
          dispatch(voiceMailSlice.actions.postVoiceMailSuccess(response.data));

          console.log('create voicemail response', response)
          if(response.data.status === true){
            dispatch(showSnackbar('Hurray.. Voicemail Successfully Created'));
          
          }else{
            dispatch(showSnackbar('Oops.. Voicemail Create Failed'));
          }
          
      } catch (error) {

          dispatch(voiceMailSlice.actions.hasError(error));
          dispatch(showSnackbar('Oops.. Voicemail Create Failed'));
      }
    };
  }


  export function updateVoiceMail(mailName, id) {

    const data = {
      "Name" : mailName
    }
    return async () => {
      dispatch(voiceMailSlice.actions.startUpdateLoading());
      try {
        const response = await axios.patch(`/voiceMail/update/${id}`, data);
          dispatch(voiceMailSlice.actions.updateVoiceMailSuccess(response.data));
  
          console.log('create voicemail response', response)
          if(response.data.status === true){
            dispatch(showSnackbar('Hurray.. Voicemail Successfully Updated'));
          
          }else{
            dispatch(showSnackbar('Oops.. Voicemail Update Failed'));
          }
          
      } catch (error) {
     
          dispatch(voiceMailSlice.actions.hasError(error));
          dispatch(showSnackbar('Oops.. Voicemail Updated Failed'));
      }
    };
  }


  export function deleteVoiceMail(id) {

   
    return async () => {
      dispatch(voiceMailSlice.actions.startLoading());
      try {
        const response = await axios.delete(`/voiceMail/delete/${id}`);
          dispatch(voiceMailSlice.actions.getVoiceMailSuccess(response.data));

          console.log('voice mail delete response', response.data)

          if(response.data.status === true){
            dispatch(showSnackbar('Hurray.. Voicemail Successfully Deleted'));
          
          }else{
            dispatch(showSnackbar('Oops.. Voicemail Deleted Failed'));
          }
 
      } catch (error) {
        dispatch(voiceMailSlice.actions.hasError(error));
        dispatch(showSnackbar('Oops.. Voicemail Deleted Failed'));
        
      }
    };
  }
  