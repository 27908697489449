import { Button } from "@mui/material";
import React, { useState } from "react";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export default function CallingPattern() {
    const [selected, setSelected] = useState(false)
    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", backgroundColor: "#FAFAFA" }} className="center-col">
            <Button style={{ marginTop: 20, width: "95%", height: 120, display: "flex", alignSelf: "center", backgroundColor: "#FAFAFA", borderRadius: 10, boxShadow: "1px 1px 1px lightgrey", display: "flex", flexDirection: "row",  justifyContent: "space-between", alignItems: "center", border: selected === true ? "1px solid #0046BB" : "1px solid #fff"}} onClick={() => setSelected(true)}>
                <div style={{ width: "30%", height: 120, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <img src={require("../../assets/phone_call.png")} style={{ height: 55, width: 65, }}/>
                </div>
                <div style={{ width: "70%", height: 120, display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: 5, }}>
                    {selected === false ? (
                        <div style={{ width: 20, height: 20, border: "1px solid lightgrey", display: "flex", alignSelf: "flex-end", flexDirection: "column", borderRadius: 10, marginTop: 5 }}/>
                    ): (
                        <div style={{ width: 20, height: 20, border: "1px solid #0046BB", borderRadius: 10, marginTop: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", alignSelf: "flex-end" }}>
                            <div style={{ width: 12, height: 12, backgroundColor: "#0046BB", borderRadius: 10 }}/>
                        </div>
                    )}
                    <div style={{ color: "#000", fontWeight: "bold", fontSize: 10 }}>
                        Phone Call
                    </div>
                    <div style={{ fontSize: 6, color: "#000", display: "flex", alignSelf: "flex-start"}}>
                        Call any number using Cellular 
                        Internet
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", }}>
                        <div style={{ width: 16, height: 16, backgroundColor: "#0046BB", borderRadius: 10, display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <CheckOutlinedIcon style={{ color: "#fff", width: 10, height: 10, display: "flex", alignSelf: "center"}}/>
                        </div>
                        <div style={{ fontSize: 6, color: "#000"}}>
                            The system dials your  and 
                            destination number
                            simultaneously
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginTop: 10 }}>
                        <div style={{ width: 16, height: 16, backgroundColor: "#0046BB", borderRadius: 10, display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <CheckOutlinedIcon style={{ color: "#fff", width: 10, height: 10, display: "flex", alignSelf: "center"}}/>
                        </div>
                        <div style={{ fontSize: 6, color: "#000"}}>
                            The system dials your  and 
                            destination number
                            simultaneously
                        </div>
                    </div>
                </div>
            </Button>
        </div>
    )
}