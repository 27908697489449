import { createSlice } from '@reduxjs/toolkit'

export const powerdialerdataslice = createSlice({
    name: 'power_dialer',
    initialState: {
        value: {},
    },
    reducers: {
        incrementpowerdialerpage: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementpowerdialerpage } = powerdialerdataslice.actions

export default powerdialerdataslice.reducer