import { createSlice } from "@reduxjs/toolkit";
import { useSnackbar } from 'notistack';
import { dispatch } from "src/redux/store";
// utils
// import axios from '../../../utils/axios';
import axios from './../../../utils/axios';
//
// import { dispatch } from '../../store';
import { getAuth } from '../getAuth';

import { showSnackbar } from '../snackbar'




const initialState = {
    isLoading: false,
    isLoadingFilter: false,
    isLoadingExport: false,
    isLoadingNotes: false,
    isStartLoadingStatus: false,
    error: null,
    inboxVoiceData: {},
    inboxVoiceSearchData: {},
    inboxVoiceFilterData: {},
    contactData: {},
    addNotesResponse: {},
    updateNotesResponse: {},
    deleteNotes: {},
    updateStatusResponse: {},
    exportDataResponse: {},
    updateInboxColumn: {},
    updateInboxDragColumn: {},
    isRefresh: false,
    userOfCompany: {}
}

const inboxCommentsSlice = createSlice({
    name: 'inboxComments',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // START LOADING
        startLoadingFilter(state) {
            state.isLoadingFilter = true;
        },

        // START LOADING
        startLoadingExport(state) {
            state.isLoadingExport = true;
        },

        // START LOADING
        startLoadingNotes(state) {
            state.isLoadingNotes = true;
        },

        // START LOADING
        startLoadingStatus(state) {
            state.isStartLoadingStatus = true;
        },

        // START LOADING
        stopLoadingStatus(state) {
            state.isStartLoadingStatus = false;
        },

        // STOP LOADING
        stopLoadingFilter(state) {
            state.isLoadingFilter = false;
        },

        // STOP LOADING
        stopLoadingExport(state) {
            state.isLoadingExport = false;
        },

        // STOP LOADING
        stopLoadingNotes(state) {
            state.isLoadingNotes = false;
        },



        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;

        },

        // GET IVR VOICE
        getCommentsSuccess(state, action) {
            state.inboxVoiceData = action.payload;
            state.isLoading = false;
        },

        getCompanyUserSuccess(state, action) {
            state.userOfCompany = action.payload;
            state.isLoading = false;
        },

    }
})



export const selectAllInboxComments = (state) => state.inboxComments;

export default inboxCommentsSlice.reducer


// Actions


export function getAllUsersOfCompany() {
    return async () => {
        dispatch(inboxCommentsSlice.actions.startLoading());
        try {

            const response = await axios.get('/users_of_company')
            dispatch(inboxCommentsSlice.actions.getCompanyUserSuccess(response.data));
            console.log('getAllUsersOfCompany Fetched : ', response)


        } catch (error) {

            console.log('getAllUsersOfCompany trigger error', error)
            dispatch(inboxCommentsSlice.actions.hasError(error));

        }
    }
}

export function getAllPost(offsetCount, rowsPerPage) {
    console.log('getIvrVoice trigger', offsetCount)
    return async () => {
        console.log('getIvrVoice trigger in', offsetCount)
        dispatch(inboxCommentsSlice.actions.startLoading());
        try {
            let link = `/meta/getFormattedPost?limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`
            const response = await axios.get(link);
            dispatch(inboxCommentsSlice.actions.getCommentsSuccess(response.data));
            console.log('Comments Fetched : ', response)
            dispatch(inboxCommentsSlice.actions.isRefreshDataCancel());

        } catch (error) {
            dispatch(inboxCommentsSlice.actions.hasError(error));
            console.log('Comments trigger error', error)
        }
    };
}

export function getAllTickets(offsetCount, rowsPerPage) {
    console.log('getAllTickets trigger', offsetCount)
    return async () => {
        console.log('getAllTickets trigger in', offsetCount)
        dispatch(inboxCommentsSlice.actions.startLoading());
        try {
            let link = `/ticket-list?limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id&populate=created_by&populate=user_id`
            const response = await axios.get(link);
            dispatch(inboxCommentsSlice.actions.getCommentsSuccess(response.data));
            console.log('Comments Fetched : ', response)
            dispatch(inboxCommentsSlice.actions.isRefreshDataCancel());

        } catch (error) {
            dispatch(inboxCommentsSlice.actions.hasError(error));
            console.log('Comments trigger error', error)
        }
    };
}

export function getAllInstaPost(offsetCount, rowsPerPage) {
    console.log('getAllInstaPost trigger', offsetCount)
    return async () => {
        console.log('getAllInstaPost trigger in', offsetCount)
        dispatch(inboxCommentsSlice.actions.startLoading());
        try {
            let link = `/insta/getFormattedPost?limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`
            const response = await axios.get(link);
            dispatch(inboxCommentsSlice.actions.getCommentsSuccess(response.data));
            console.log('getAllInstaPost Fetched : ', response)
            dispatch(inboxCommentsSlice.actions.isRefreshDataCancel());

        } catch (error) {
            dispatch(inboxCommentsSlice.actions.hasError(error));
            console.log('getAllInstaPost trigger error', error)
        }
    };
}