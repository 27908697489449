import axios from 'axios';

export const REACT_APP_BASE_URL = "https://prodback.vibtree.com"
export const baseUrl = `${REACT_APP_BASE_URL}/api`
export const sobotUrl = "http://dev-api.sobot.in"
export const awsAccessKey = "AKIA2MTCLJCGTO3X7AJJ"
export const awsSecretAccessKey = "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"
export const awsRegion = "ap-south-1"
export const muiKey = "799b4ae9b3ce1d4ca6b943a7f5db24ebTz00Nzg1OSxFPTE2OTAyMjI2NzE2NjksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 30000,
  headers: {
    authorization: localStorage.getItem('redux-token'),
    'Access-Control-Allow-Origin': '*'
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
