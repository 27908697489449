import { createSlice } from '@reduxjs/toolkit'

export const fileuploadtypeSlice = createSlice({
    name: 'upload_type',
    initialState: {
        value: {},
    },
    reducers: {
        incrementUploadtype: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementUploadtype } = fileuploadtypeSlice.actions

export default fileuploadtypeSlice.reducer