
import { createSlice } from "@reduxjs/toolkit";

import { dispatch } from '../store';

const initialState = {
  successSnackbarOpen: false,
  message: ''
}

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {

    SNACKBAR_SUCCESS(state, action){
      state.successSnackbarOpen = true;
      state.message = action.payload;
      console.log('for snack in reducer success true')
    },

    SNACKBAR_CLEAR(state, action){
      state.successSnackbarOpen = false;
    }
    
  }
})

export const selectSnackbar = (state) => state.snackbar;

export default snackbarSlice.reducer

  

  export const showSnackbar = message => {
    console.log('for snack in reducer success')
    return dispatch => {
      dispatch(snackbarSlice.actions.SNACKBAR_SUCCESS(message));
      
    };
  };
  
  export const clearSnackbar = () => {
    return dispatch => {
      dispatch(snackbarSlice.actions.SNACKBAR_CLEAR());
    };
  };
