

import { useEffect, useState, useRef } from "react";
import { Snackbar, Alert, Typography } from '@mui/material';



export default function OnlineOffline({ itIsFrom }) {
  const [status, setStatus] = useState(true);
  const [hideStatus, setHideStatus] = useState(false)
  // const pingServerInterval = useRef<ReturnType<typeof setInterval>>();
  // const pingServerAbortController = useRef<AbortController>();

  const pingServerInterval = useRef();
  const pingServerAbortController = useRef();

  const pingServer = async () => {
    pingServerAbortController.current = new AbortController();

    try {
      const response = await fetch("/", {
        method: "HEAD",
        signal: pingServerAbortController.current.signal
      });

      return response.status >= 200 && response.status < 300; // either true or false
    } catch (err) {
      return false; // definitely offline
    }
  };

  const pingServerClearInterval = () => {
    if (pingServerInterval.current) {
      clearInterval(pingServerInterval.current);
    }
  };

  const pingServerAbort = () => {
    if (pingServerAbortController.current) {
      pingServerAbortController.current.abort();
    }
  };

  useEffect(() => {
    pingServer().then((isOnline) => setStatus(isOnline));

    const setStatusOffline = () => {
      pingServerClearInterval();
      pingServerAbort();
      setStatus(false);
    };

    const setStatusOnline = async () => {
      pingServerClearInterval();

      const isOnline = await pingServer();

      if (isOnline) {
        setStatus(true);
      } else {
        pingServerInterval.current = setInterval(async () => {
          pingServerAbort();

          const isOnline = await pingServer();

          if (isOnline) {
            setStatus(true);
            pingServerClearInterval();
          }
        }, 1000);
      }
    };

    window.addEventListener("offline", setStatusOffline);
    window.addEventListener("online", setStatusOnline);

    return () => {
      pingServerClearInterval();
      pingServerAbort();
      window.removeEventListener("offline", setStatusOffline);
      window.removeEventListener("online", setStatusOnline);
    };
  }, []);




  return (
    <>


      {/* <Snackbar
  open={hideStatus}
  autoHideDuration={3000}
  anchorOrigin={{
    vertical: "top",
    horizontal: "right"
  }}
  >
  <Alert severity="success" sx={{ width: '100%' }}>
    Hurray...! back online
  </Alert>
</Snackbar> */}
      {itIsFrom === 'app' && (

        <Snackbar
          open={!status}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            You are offline! check your connection
          </Alert>
        </Snackbar>
      )}

      {itIsFrom === 'navbar' && (
        <>
          {status ? (
            <>{localStorage.setItem('statusonlineoffline', 'online')}
              <Typography variant="caption" noWrap sx={{ color: '#54D62C', display: 'block', textAlign: 'left' }}>online</Typography>
            </>
          ) : (
            <>
              {localStorage.setItem('statusonlineoffline', 'offline')}
              <Typography variant="caption" noWrap sx={{ color: '#FF4842', display: 'block', textAlign: 'left' }}>offline</Typography></>
          )}
        </>
      )}



    </>
  );
}
