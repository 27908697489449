import { createSlice } from '@reduxjs/toolkit'

export const fileuploadmodifySlice = createSlice({
    name: 'upload_modify',
    initialState: {
        value: {},
    },
    reducers: {
        incrementUploadmodify: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementUploadmodify } = fileuploadmodifySlice.actions

export default fileuploadmodifySlice.reducer