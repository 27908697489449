import _mock from './_mock';

// ----------------------------------------------------------------------

export const _bankingContacts = [...Array(12)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  email: _mock.email(index),
  avatar: _mock.image.avatar(index),
}));

export const _bankingCreditCard = [
  {
    id: _mock.id(2),
    balance: 23432.03,
    cardType: 'mastercard',
    cardHolder: _mock.name.fullName(2),
    cardNumber: '**** **** **** 3640',
    cardValid: '11/22',
  },
  {
    id: _mock.id(3),
    balance: 18000.23,
    cardType: 'visa',
    cardHolder: _mock.name.fullName(3),
    cardNumber: '**** **** **** 8864',
    cardValid: '11/25',
  },
  {
    id: _mock.id(4),
    balance: 2000.89,
    cardType: 'mastercard',
    cardHolder: _mock.name.fullName(4),
    cardNumber: '**** **** **** 7755',
    cardValid: '11/22',
  },
];

export const _bankingRecentTransitions = [
  {
    id: _mock.id(2),
    contact: 'Donald Trump',
    number: 9567541955,
    avatar: _mock.image.avatar(8),
    startTime: 'Few Minutes Ago',
    duration: '00:22',
    phone: '6985784558',
    user: 'Sreeju R',
    callType: 'Incoming',
    officeType: 'Head Office',
    status: 'completed'
  },
  {
    id: _mock.id(3),
    contact: 'Modi',
    number: 9567541955,
    avatar: _mock.image.avatar(7),
    startTime: 'Few Minutes Ago',
    duration: '00:22',
    phone: '6985784558',
    user: 'Danish',
    callType: 'Incoming',
    officeType: 'Branch Office',
    status: 'completed'
  },
  {
    id: _mock.id(4),
    contact: 'Putin',
    number: 9567541955,
    avatar: _mock.image.avatar(6),
    startTime: 'Few Minutes Ago',
    duration: '00:22',
    phone: '6985784558',
    user: 'Abhishek Gupta',
    callType: 'Outgoing',
    officeType: 'Head Office',
    status: 'failed'
  },
  {
    id: _mock.id(5),
    contact: 'Donald Trump',
    number: 9567541955,
    avatar: _mock.image.avatar(4),
    startTime: 'Few Minutes Ago',
    duration: '00:22',
    phone: '6985784558',
    user: 'Sreeju R',
    callType: 'Incoming',
    officeType: 'Head Office',
    status: 'in_progress'
  },
  {
    id: _mock.id(6),
    contact: 'Donald Trump',
    number: 9567541955,
    avatar: _mock.image.avatar(3),
    startTime: 'Few Minutes Ago',
    duration: '00:22',
    phone: '6985784558',
    user: 'Sreeju R',
    callType: 'Incoming',
    officeType: 'Head Office',
    status: 'completed'
  },
];
