import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Link, Button, Typography, Grid, Skeleton } from '@mui/material';
// utils
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fDate } from '../../../../utils/formatTime';
import { fCurrency } from '../../../../utils/formatNumber';
// components
import Iconify from '../../../../components/Iconify';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { vikalp } from './Data'
import axiosInstance, { baseUrl } from 'src/utils/axios';
// ----------------------------------------------------------------------

AccountBillingInvoiceHistory.propTypes = {
  invoices: PropTypes.array,
};

export default function AccountBillingInvoiceHistory({ invoices }) {


  const dt = () => {
    vikalp.map((option) => {
      console.log(option.date)
    })
  }
  useEffect(() => {
    dt()
  }, [])
  const [loader, setloader] = useState(true)
  const [invdata, setinvdata] = useState([])
  // const dts = () => {

  //   axiosInstance.get(`${baseUrl}/users-transactions?organizationId=60014123540`).then((res) => {
  //     console.log("organistion", res.data.data.data.transactions)
  //     setinvdata(res.data.data.data.transactions)
  //   }).catch((err) => {
  //     console.log("organisation err", err)
  //   })
  // }

  // useEffect(() => {
  //   dts()
  // }, [])
  const customerIdInZoho = localStorage.getItem('customerIdInZoho')
  const invoicehistory = () => {

    axiosInstance.get(`${baseUrl}/v2/users-transactions?customer_id=${customerIdInZoho}`)
      .then((res) => {
        console.log("invoicehistory", res.data.data)
        setinvdata(res.data.data)
        setloader(false)
        // localStorage.setItem("transactionsid",res.data.data)
      }).catch((err) => {
        console.log(err)
        setloader(false)
      })
  }

  useEffect(() => {
    invoicehistory()
  }, [])



  // const tokengst = '1000.c7cb962b39f4185fd86b6119bb831395.e22fdaeb23356bc8811b119ccc41017f'
  // const apiUrl = "https://subscriptions.zoho.in/api"
  // const authAxiosgst = axios.create({
  //   baseURL: apiUrl,
  //   headers: {
  //     "Authorization": `Zoho-oauthtoken ${tokengst}`
  //   }
  // })

  // var config = {
  //   method: 'get',
  //   url: 'https://subscriptions.zoho.in/api/v1/transactions',
  //   headers: {
  //     'X-com-zoho-subscriptions-organizationid': '60014123540',
  //     'Authorization': 'Zoho-oauthtoken 1000.c7cb962b39f4185fd86b6119bb831395.e22fdaeb23356bc8811b119ccc41017f',
  //     'Cookie': 'JSESSIONID=88F3B1FCA7D36DBDC924E4889DE41C1B; _zcsr_tmp=9223d1e4-413f-41c5-9faa-18ef34bf3d0b; d6aff65234=47c623675db4e599dccb022521969759; zsmcscook=9223d1e4-413f-41c5-9faa-18ef34bf3d0b; JSESSIONID=5709D16074874DC6E563FD7D20DBCB0F; _zcsr_tmp=95335dcd-4bed-47c5-929b-c8bcd40f3028; d6aff65234=47c623675db4e599dccb022521969759; zsmcscook=95335dcd-4bed-47c5-929b-c8bcd40f3028'
  //   }
  // };

  // axios(config)
  //   .then(function (response) {
  //     console.log(JSON.stringify(response.data));
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });

  // const datafetch = () => {
  //   authAxiosgst.get(`https://subscriptions.zoho.in/api/v1/transactions`).then((res) => {
  //     console.log("inbox", res)
  //   }).catch((err) => {
  //     console.log("err", err)
  //   })
  // }

  // useEffect(() => {
  //   datafetch()
  // }, [])

  const handleEdit = (id) => {
    console.log('iddddddddd', id)
    localStorage.setItem('transaction_id', id.transaction_id)
  }
  const skeltonLoaders = (
    <>
      <Grid container>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>


      </Grid>
    </>
  )

  return (
    <Stack spacing={3} alignItems="flex-end">
      <Typography variant="subtitle1" sx={{ width: 1 }}>
        Invoice History
      </Typography>

      <Stack spacing={2} sx={{ width: 1, height: "500px", overflow: "scroll", }}>

        {
          loader == true ? <>
            {skeltonLoaders}
          </> : <>
            {
              invdata.map((option) => {
                return <Stack
                  // key={invoice.id} 
                  direction="row" justifyContent="space-between" sx={{ width: 1 }}>
                  <Typography variant="body2" sx={{ minWidth: 160 }}>
                    {/* {fDate(invoice.createdAt)} */}
                    {option.date}
                  </Typography>
                  <Typography variant="body2">
                    {/* {fCurrency(invoice.price)} */}
                    {option.amount}
                  </Typography>
                  <Link component={RouterLink} to={PATH_DASHBOARD.user.accountinvoice('e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5')} onClick={() => handleEdit(option)}>
                    View
                  </Link>
                </Stack>
              })
            }
          </>
        }



        {/* {invoices.map((invoice) => (
          <Stack key={invoice.id} direction="row" justifyContent="space-between" sx={{ width: 1 }}>
            <Typography variant="body2" sx={{ minWidth: 160 }}>
              {fDate(invoice.createdAt)}
            </Typography>
            <Typography variant="body2">{fCurrency(invoice.price)}</Typography>
            <Link component={RouterLink} to="#">
              PDF
            </Link>
          </Stack>
        ))} */}

      </Stack>

      {/* <Button size="small" endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}>
        All invoices
      </Button> */}
    </Stack>
  );
}
