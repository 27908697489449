import { createSlice } from '@reduxjs/toolkit'

export const poweronslice = createSlice({
    name: 'power_dialer',
    initialState: {
        value: false,
    },
    reducers: {
        incrementpoweronbutton: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementpoweronbutton } = poweronslice.actions

export default poweronslice.reducer