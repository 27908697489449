import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, OutlinedInput, InputAdornment, FormHelperText, TextField, Typography, Link, Card, Snackbar, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import OtpInput from 'react-otp-input';
import axiosInstance, { baseUrl } from 'src/utils/axios';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Successfull from './Successfull';
// ----------------------------------------------------------------------

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}




export default function NewPasswordForm() {
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [otp, setotp] = useState('')
  const { enqueueSnackbar } = useSnackbar();
  const [pagechange, setpagechange] = useState('first')
  const [showPassword, setShowPassword] = useState(false);
  const [confirmpassword, setconfirmpassword] = useState('');
  const [password, setpassword] = useState('');
  const emailRecovery = sessionStorage.getItem('email-recovery');
  const [loadings, setloadings] = useState(false);
  const [passwordValid, setPasswordValid] = useState(null);
  const handleClickShowPassword = () => {
    setShowPassword(showPassword => !showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required('Code is required'),
    code2: Yup.string().required('Code is required'),
    code3: Yup.string().required('Code is required'),
    code4: Yup.string().required('Code is required'),
    code5: Yup.string().required('Code is required'),
    code6: Yup.string().required('Code is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
    email: emailRecovery || '',
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  useEffect(() => {
    const target = document.querySelector('input.field-code');

    target?.addEventListener('paste', handlePaste);

    return () => {
      target?.removeEventListener('paste', handlePaste);
    };


  }, []);

  const handlePaste = (event) => {
    let data = event.clipboardData.getData('text');

    data = data.split('');

    [].forEach.call(document.querySelectorAll('.field-code'), (node, index) => {
      node.value = data[index];

      const fieldIndex = `code${index + 1}`;

      setValue(fieldIndex, data[index]);
    });

    event.preventDefault();
  };

  const handleChangeWithNextField = (event, handleChange) => {
    const { maxLength, value, name } = event.target;

    const fieldIndex = name.replace('code', '');

    const fieldIntIndex = Number(fieldIndex);

    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(`input[name=code${fieldIntIndex + 1}]`);

        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }

    handleChange(event);
  };

  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      console.log('data:', {
        email: data.email,
        code: `${data.code1}${data.code2}${data.code3}${data.code4}${data.code5}${data.code6}`,
        password: data.password,
      });

      sessionStorage.removeItem('email-recovery');

      enqueueSnackbar('Change password success!');

      navigate(PATH_DASHBOARD.root, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };
  // const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //     return;
    // }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const [open2, setOpen2] = useState(false);
  const handleClose2 = (event, reason) => {
    // if (reason === 'clickaway') {
    //     return;
    // }

    setOpen2(false);
  };

  const handleClick2 = () => {
    setOpen2(true);
  };


  const Emailsent = localStorage.getItem('email')
  const otpsend = () => {

    // setloadings(true)
    var obj = {
      email: Emailsent
    }
    console.log(email)
    localStorage.setItem('email', email)
    var payloadobj = JSON.stringify(obj)
    axios.post(`${baseUrl}/send-otp`, obj).then((res) => {
      console.log(res)
      setloadings(false)

      // navigate('/auth/new-password')

    }).catch((err) => {
      console.log(err)
      // setloadings(false)
    })
  }

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const action2 = (
    <>
      <Button color="secondary" size="small" onClick={handleClose2}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose2}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );


  const handleopen = () => {


    setTimeout(() => {
      setOpen(false)
    }, 2000)
  }

  const [showfiled, setshowfield] = useState(false);

  const handlechangeotp = (e) => {
    console.log(e)
    if (e.length === 6) {
      verifyotp(e)
    } else {
      setshowfield(false)
    }
  }

  const email = localStorage.getItem('email')
  const verifyotp = (e) => {
    // if (otp.length == 6) {
    //   setloadings(true)
    // }
    const obj = {
      email: email,
      otp: JSON.parse(e)
    }

    axios.post(`${baseUrl}/auth/verify`, obj).then((res) => {
      console.log(res.data.message)
      if (res.data.message == "success") {
        // setstep(step + 1)
        // setloadings(false);
        setshowfield(true)
        console.log(res)
        // setpagechange('second')
      } else {
        setshowfield(false)
        console.log("vikalp")
        // handleClick()
        // console.log("notfound")
        // setloadings(false)
      }

    })
      .catch((err) => {
        setshowfield(false)
        console.log(err)
      })
  }
  const changepassword = () => {
    setloadings(true)
    axiosInstance.post(`${baseUrl}/user/reset-password`, {
      email: email,
      username: email,
      password: password
    }).then((res) => {
      handleClick()
      console.log(res)
      console.log(res.data.status)
      setloadings(false)
      if (res.data.status === true) {
        handleClick()
        setTimeout(() => {
          navigate('/')
        }, 1200)

        // setpagechange('first')
      }
    }).catch((err) => {
      setloadings(false)
      console.log(err)
    })
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {pagechange == 'first' ? <>
        <Stack spacing={3}>
          <Typography variant="h3" gutterBottom>
            Request sent successfully!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            We've sent a 6-digit confirmation email to your email.
            <br />
            Please enter the code in below box to verify your email.
          </Typography>

          {/* <TextField name="email" disabled value={email} /> */}

          <Stack direction="row" spacing={2} justifyContent="center">
            {width < 500 ? <>
              <OtpInput
                inputStyle={{ width: "35px ", height: "35px", borderRadius: "10px", border: "1px solid #0045BA", }}
                value={otp}
                onChange={(value) => {
                  setotp(value)
                  // console.log(value)
                  handlechangeotp(value)
                }}
                numInputs={6}
                separator={<span style={{ padding: "5px 10px" }}></span>}
              />
            </> : <>
              <OtpInput
                inputStyle={{ width: "60px ", height: "60px", borderRadius: "10px", border: "1px solid #0045BA", }}
                value={otp}
                onChange={(value) => {
                  setotp(value)
                  // console.log(value)
                  handlechangeotp(value)
                }}
                numInputs={6}
                separator={<span style={{ padding: "5px 10px" }}></span>}
              />
            </>}


            {/* {['code1', 'code2', 'code3', 'code4', 'code5', 'code6'].map((name, index) => (
            <Controller
              key={name}
              name={`code${index + 1}`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <OutlinedInput
                  {...field}
                  error={!!error}
                  autoFocus={index === 0}
                  placeholder="-"
                  onChange={(event) => handleChangeWithNextField(event, field.onChange)}
                  inputProps={{
                    className: 'field-code',
                    maxLength: 1,
                    sx: {
                      p: 0,
                      textAlign: 'center',
                      width: { xs: 36, sm: 56 },
                      height: { xs: 36, sm: 56 },
                    },
                  }}
                />
              )}
            />
          ))} */}
          </Stack>
          <TextField
            type={showPassword ? 'text' : 'password'}
            name='password' label='Password'
            value={password} onChange={(e) => {
              setpassword(e.target.value)
              setPasswordValid(
                /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&]{8,}$/.test(
                  e.target.value,
                ) && e.target.value.length >= 8,
              );
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>


                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="small"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>

                  {/* {(() => {  if (passwordValid === null) {
                                                    return <img className="input-icon" src={lockIcon} alt="User Icon" />;
                                                } if (passwordValid) {
                                                    return <TickMark />;
                                                }
                                                return <CrossMark />;
                                  })()} */}

                </InputAdornment>
              ),
            }}
            fullWidth
            disabled={showfiled == false}

          />


          <TextField name='confirmpassword' label='Confirm password' fullWidth
            value={confirmpassword} onChange={(e) => setconfirmpassword(e.target.value)}
            disabled={showfiled == false}


          />
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loadings}
            // disabled={!(username === password) || username.length === 0
            // }

            disabled={!(confirmpassword === password) || (showfiled == false)}
            onClick={() => {
              // setModalOpen(true)
              changepassword()
            }}
            style={{ height: "40px", marginTop: "20px", display: "block", width: "100%", }}
          >
            Save
          </LoadingButton>
          {/* <LoadingButton
            variant="contained"
            color="primary"
            // loading={loadings}
            onClick={() => {
              verifyotp()


            }}
            style={{ height: "40px", marginTop: "20px", display: "block", width: "100%" }}
          >
            Verify
          </LoadingButton> */}
          <Typography variant="body2">
            Don’t have a code? &nbsp;
            <Link variant="subtitle2" onClick={() => {
              otpsend()
              handleClick2()
            }} style={{ cursor: "pointer" }}>
              Resend code
            </Link>
          </Typography>
          {modalOpen && <Successfull setOpenModal={setModalOpen} />}
          {/* <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="confirmPassword"
          label="Confirm New Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 3 }}>
          Change password
        </LoadingButton> */}
        </Stack>
      </>
        :
        "null"

      }
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: "right"
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Password change successfully"
        action={action}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: "right"
        }}
        open={open2}
        autoHideDuration={3000}
        onClose={handleClose2}
        message="Otp send successfully"
        action={action2}
      />

    </FormProvider>
  );
}
