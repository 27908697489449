import { createSlice } from '@reduxjs/toolkit'

export const ChannelchooseSlice = createSlice({
    name: 'upload_name',
    initialState: {
        value: '',
    },
    reducers: {
        incrementChannelchoose: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementChannelchoose } = ChannelchooseSlice.actions

export default ChannelchooseSlice.reducer