import { createSlice } from '@reduxjs/toolkit'

export const ViberbottokenSlice = createSlice({
    name: 'Viber',
    initialState: {
        value: '',
        validate: false
    },
    reducers: {
        incrementviberbottoken: (state, { payload }) => {
            state.value = payload
        },

        incrementviberbottokenValidate: (state, { payload }) => {
            state.validate = payload
        }
    },
})

// export const ViberbottokenSliceValidate = createSlice({
//     name: 'ViberValidate',
//     initialState: {
//         validate: ''
//     },
//     reducers: {
//         incrementviberbottoken: (state, { validate }) => {
//             state.validate = validate
//         },

//         incrementviberbottoken1: (state, { validate }) => {
//             state.validate = validate
//         }
//     },
// })


// Action creators are generated for each case reducer function
export const { incrementviberbottoken, incrementviberbottokenValidate } = ViberbottokenSlice.actions

export default ViberbottokenSlice.reducer

