import { createSlice } from '@reduxjs/toolkit'

export const ChanneladduserSlice = createSlice({
    name: 'channel_adduser',
    initialState: {
        value: '',
        validate: false,
    },
    reducers: {
        incrementChanneladduser: (state, { payload }) => {
            state.value = payload
        },
        incrementChanneladduserValidate: (state, { payload }) => {
            state.validate = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementChanneladduser, incrementChanneladduserValidate } = ChanneladduserSlice.actions

export default ChanneladduserSlice.reducer