import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from '../../utils/axios';

import { dispatch } from '../store';

import {showSnackbar} from './snackbar'

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    ivrData: {},
    ivrNewResponse: {},
    ivrDeleteResponse: {},
    removeEdgeFlag: false,
    ivrNameUpdateResponse: {},
    ivrStatusUpdateResponse: {},
}

const ivrSlice = createSlice({
    name: 'ivrStudio',
    initialState,
    reducers: {

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET NUMBER SSUCCESS
        getIvrSuccess(state, action) {
            state.ivrData = action.payload;
            state.isLoading = false;
          },

        // POST NUMBER SSUCCESS
        postNewIvrSuccess(state, action) {
          state.ivrNewResponse = action.payload;
          state.isLoading = false;
        },

        // POST NUMBER SSUCCESS
        deleteIvrSuccess(state, action) {
          state.ivrDeleteResponse = action.payload;
          state.isLoading = false;
        },

         // flag
         changeFlag(state, action) {
          state.removeEdgeFlag = action.payload;
          state.isLoading = false;
        },

        // put name SSUCCESS
        putFlowNameSuccess(state, action) {
          state.ivrNameUpdateResponse = action.payload;
          state.isLoading = false;
        },

        // put status SSUCCESS
        putFlowStatusSuccess(state, action) {
          state.ivrStatusUpdateResponse = action.payload;
          state.isLoading = false;
        },


        
    }
})


export const selectIvrStudio = (state) => state.ivrStudio;

export default ivrSlice.reducer


// Actions

export function getIvrStudioData(page, offset) {

    return async () => {
      dispatch(ivrSlice.actions.startLoading());
      try {
        const response = await axios.get(`/ivrFlow?page=${page}&offset=${offset}&limit=10`);
          dispatch(ivrSlice.actions.getIvrSuccess(response.data));
          
      } catch (error) {
        dispatch(ivrSlice.actions.hasError(error));
      }
    };
  }


  export function postNewIvr(data) {
    console.log('redux post ivr')


    return async () => {
      dispatch(ivrSlice.actions.startLoading());
      try {
        const response = await axios.post(`/ivrFlow`, data);
          dispatch(ivrSlice.actions.postNewIvrSuccess(response.data));
          console.log('ivr create redux response', response)
          if(response.data.status === true){
            dispatch(showSnackbar('Hurray.. Flow Successfully created'));
     
          }else{
            dispatch(showSnackbar('Oops.. Flow creating Failed'));
          }
          
      } catch (error) {
          dispatch(ivrSlice.actions.hasError(error));
          dispatch(showSnackbar('Oops.. Flow creating Failed'));
      }
    };
  }

  export function deleteFlow(id) {
    console.log('redux delete ivr')


    return async () => {
      dispatch(ivrSlice.actions.startLoading());
      try {
        const response = await axios.delete(`/ivrFlow/${id}`);
          dispatch(ivrSlice.actions.deleteIvrSuccess(response.data));
          console.log('ivr create redux response', response)
          if(response.data.status === true){
            dispatch(showSnackbar('Hurray.. Flow Successfully Deleted'));
     
          }else{
            dispatch(showSnackbar('Oops.. Flow Deleting Failed'));
          }
          
      } catch (error) {
          dispatch(ivrSlice.actions.hasError(error));
          dispatch(showSnackbar('Oops.. Flow creating Failed'));
      }
    };
  }


  export function getIvFlag(data) {

    return async () => {
      dispatch(ivrSlice.actions.startLoading());
      try {
          dispatch(ivrSlice.actions.changeFlag(data));
 
      } catch (error) {
        dispatch(ivrSlice.actions.hasError(error));
      }
    };
  }


  export function updateFlowName(id, data) {
    console.log('redux put ivr')


    return async () => {
      dispatch(ivrSlice.actions.startLoading());
      try {
        const response = await axios.put(`/ivrFlow/${id}`, data);
          dispatch(ivrSlice.actions.putFlowNameSuccess(response.data));
          console.log('ivr put redux response', response)
          if(response.data.status === true){
            dispatch(showSnackbar('Hurray.. Flow Name Successfully Updated'));
     
          }else{
            dispatch(showSnackbar('Oops.. Flow update Failed'));
          }
          
      } catch (error) {
          dispatch(ivrSlice.actions.hasError(error));
          dispatch(showSnackbar('Oops.. Flow update Failed'));
      }
    };
  }

  export function updateFlowStatus(id, data) {
    console.log('redux updateFlowStatus', data)


    return async () => {
      dispatch(ivrSlice.actions.startLoading());
      try {
        const response = await axios.put(`/ivrFlow/${id}`, data);
          dispatch(ivrSlice.actions.putFlowStatusSuccess(response.data));
          console.log('updateFlowStatus redux response', response)
          if(response.data.status === true){
            dispatch(showSnackbar('Hurray.. Flow Status Successfully Updated'));
     
          }else{
            dispatch(showSnackbar('Oops.. Flow update Failed'));
          }
          
      } catch (error) {
          dispatch(ivrSlice.actions.hasError(error));
          dispatch(showSnackbar('Oops.. Flow update Failed'));
      }
    };
  }
