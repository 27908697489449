import { createSlice } from '@reduxjs/toolkit'

export const smspeidslice = createSlice({
    name: 'sms_peid',
    initialState: {
        value: '',
    },
    reducers: {
        incrementsmspeid: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementsmspeid } = smspeidslice.actions

export default smspeidslice.reducer