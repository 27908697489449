import { createSlice } from '@reduxjs/toolkit'

export const whatsappnumberSlice = createSlice({
    name: 'whatsapp_number',
    initialState: {
        value: '',
    },
    reducers: {
        incrementwhatsappnumber: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementwhatsappnumber } = whatsappnumberSlice.actions

export default whatsappnumberSlice.reducer