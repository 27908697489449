import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list : [],
    no_of_fetched_conversations : 0,
    no_of_fetch_count : 0,
    changeInConversation : []
}

export const whatsappConversationListSlice = createSlice({
    name:'whatsapp_socket_messages',
    initialState,
    reducers: {
        addConversationInList(state, {payload}) {
            console.log("payload receive from socket for conversation :  ", payload)
            
         
                state.no_of_fetched_conversations = payload.no_of_fetched_conversations;
                state.list = payload.list;
                state.no_of_fetch_count = state.no_of_fetch_count + 1;
                state.changeInConversation = payload.changeInConversation;

        }
    }
});

export const { addConversationInList } = whatsappConversationListSlice.actions;

export default whatsappConversationListSlice.reducer;