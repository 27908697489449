import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  fetchIvrFilterData : 0 ,
  isFilteredClearForIvrStudiousCdr : false ,
}

export const renderCounterSlice = createSlice({
  
  name: 'render',
  initialState,
  reducers: {
    incrementRender: (state) => {
      state.value += 1
    },
    incrementFetchIvrFilterData: (state) => {
      state.fetchIvrFilterData += 1 
    },
    toggleIsFilteredClearForIvrStudiousCdr: (state , value ) => {
      console.log("value in redux : ", value )
      // state.isFilteredClearForIvrStudiousCdr = !state.isFilteredClearForIvrStudiousCdr
      state.isFilteredClearForIvrStudiousCdr = value.payload
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
  },
})

export const { incrementRender, decrement, incrementByAmount , incrementFetchIvrFilterData , toggleIsFilteredClearForIvrStudiousCdr } = renderCounterSlice.actions

export default renderCounterSlice.reducer