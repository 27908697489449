import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';


const initialState = {
    isLoading: false,
    error: null,
    loginData: {
      username: '',
      password: '',
    },
  };

  
const slice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
        state.isLoading = true;
      },

      // HAS ERROR
        hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
    }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;