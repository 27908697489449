import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from '../../utils/axios';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------


const initialState= {
    isLoading: false,
    error: null,
    companyData: {},
    usersData: {},
}

const getCompanySlice = createSlice({
    name: 'getCompany',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        
        // GET USER DATA SSUCCESS
        getCompanySuccess(state, action) {
          state.companyData = action.payload;
          state.isLoading = false;
          console.log('redux user auth data', state.companyData)
        },

        // GET USER DATA SSUCCESS
        getUsersSuccess(state, action) {
          state.usersData = action.payload;
          state.isLoading = false;
        },

      
       

    }
})

export const selectgetCompany = (state) => state.getCompany;

export default getCompanySlice.reducer

// Actions


  export function getCompany(id) {
    return async () => {
      dispatch(getCompanySlice.actions.startLoading());
      try {
        const response = await axios.get(`/companies/${id}`);
          dispatch(getCompanySlice.actions.getCompanySuccess(response.data));
      } catch (error) {
        dispatch(getCompanySlice.actions.hasError(error));
      }
    };
  }

  export function getUsers(id) {
    return async () => {
      dispatch(getCompanySlice.actions.startLoading());
      try {
        const response = await axios.get(`/users?auth_id=${id}`);
          dispatch(getCompanySlice.actions.getUsersSuccess(response.data));
      } catch (error) {
        dispatch(getCompanySlice.actions.hasError(error));
      }
    };
  }
