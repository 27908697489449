import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from '../../../utils/axios';

//
import { dispatch } from '../../store';

import {showSnackbar} from '../snackbar'

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    numberData: {},
    cancelNumberResponse: {},
    editNameResponse: {},
}

const manageNumberSlice = createSlice({
    name: 'manageNumber',
    initialState,
    reducers: {

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET NUMBER SSUCCESS
        getNumberSuccess(state, action) {
            state.numberData = action.payload;
            state.isLoading = false;
          },

        // CANCEL SSUCCESS
        cancelNumberSuccess(state, action) {
            state.cancelNumberResponse = action.payload;
            state.isLoading = false;
          },

          // EDIT NAME SSUCCESS
        editNameSuccess(state, action) {
          state.editNameResponse = action.payload;
          state.isLoading = false;
        },
    }
})

export const selectManageNumbers = (state) => state.manageNumber;

export default manageNumberSlice.reducer

// Actions

export function getManageNumbers() {

    return async () => {
      dispatch(manageNumberSlice.actions.startLoading());
      try {
        const response = await axios.post(`/getavailablenumbers`);
          dispatch(manageNumberSlice.actions.getNumberSuccess(response.data));
          
      } catch (error) {
        dispatch(manageNumberSlice.actions.hasError(error));
      }
    };
  }


  export function cancelNumber(data) {

    return async () => {
      dispatch(manageNumberSlice.actions.startLoading());
      try {
        const response = await axios.post(`/cancelNumber`, data);
          dispatch(manageNumberSlice.actions.cancelNumberSuccess(response.data));


          if(response.data.status === true){
           
            dispatch(showSnackbar('Hurray.. Number Successfully Cancelled'));
            dispatch(getManageNumbers)
          }else{
    
            dispatch(showSnackbar('Oops.. Number Cancellation Failed'));
          }

      } catch (error) {
        dispatch(manageNumberSlice.actions.hasError(error));
        dispatch(showSnackbar('Oops.. Number Cancellation Failed'));
      }
    };
  }

  export function editName(id, name) {

    return async () => {
      dispatch(manageNumberSlice.actions.startLoading());
      try {
        const response = await axios.put(`/edit/number/${id}`,{"name": name});
          dispatch(manageNumberSlice.actions.editNameSuccess(response.data));
         
          dispatch(getManageNumbers)
          if(response.data.status === true){

            dispatch(showSnackbar('Hurray.. Name Successfully Updated'));
          }else{

            dispatch(showSnackbar('Oops.. Trouble while saving Name'));
          }

      } catch (error) {
        dispatch(manageNumberSlice.actions.hasError(error));
        dispatch(showSnackbar('Oops.. Trouble while saving Name'));
      }
    };
  }