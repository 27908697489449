import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  cloudnumber : "",
  caller : "",
  sourceFromWhereCallerIsComing : "Ivr",
  makeCallTrigerValue : "clickToCall",
}

export const clickToCallSlice = createSlice({
  
  name: 'clickToCall',
  initialState,
  reducers: {
    incrementRender: (state) => {
      state.value += 1
    },
    setCaller: (state , value) => {
      state.caller = value.payload 
    },
    setCloudNumber: (state , value) => {
        state.cloudnumber = value.payload 
    },
    setmakeCallTrigerValue: (state , value ) => {
      state.makeCallTrigerValue = value.payload
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
  },
})

export const { incrementRender, setCaller , setCloudNumber ,setmakeCallTrigerValue, decrement, incrementByAmount  } = clickToCallSlice.actions

export default clickToCallSlice.reducer