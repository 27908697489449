import { createSlice } from '@reduxjs/toolkit'

export const deleteidSlice = createSlice({
    name: 'auth_id',
    initialState: {
        value: '',
    },
    reducers: {
        incrementdeleteid: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementdeleteid } = deleteidSlice.actions

export default deleteidSlice.reducer