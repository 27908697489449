import { createSlice } from '@reduxjs/toolkit'

export const Selectedbuttonslice = createSlice({
    name: 'selected_button',
    initialState: {
        value: false,
    },
    reducers: {
        incrementSelectedButton: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementSelectedButton } = Selectedbuttonslice.actions

export default Selectedbuttonslice.reducer