import { createSlice } from '@reduxjs/toolkit'

export const fileuploadnameSlice = createSlice({
    name: 'upload_name',
    initialState: {
        value: {},
    },
    reducers: {
        incrementUploadname: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementUploadname } = fileuploadnameSlice.actions

export default fileuploadnameSlice.reducer