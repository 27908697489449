import { createSlice } from '@reduxjs/toolkit'

export const refreshinboxslice = createSlice({
    name: 'refresh_inbox',
    initialState: {
        value: '',
    },
    reducers: {
        incrementrefreshinbox: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementrefreshinbox } = refreshinboxslice.actions

export default refreshinboxslice.reducer