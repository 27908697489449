import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value : null
}

export const fromNumberSlice = createSlice({
    name: "phoneNumber",
    initialState,
    reducers: {
        setFromNumber: (state, action) => {
            state.value = action.payload
        },
    },
});

export const { setFromNumber } = fromNumberSlice.actions;
export default fromNumberSlice.reducer;