import { action, computed, thunk } from "easy-peasy";
import {
  addDays,
  firstMondayOfWeek,
  today,
  firstDayOfMonth,
  firstDayOfLastMonth,
  lastDayOfMonth,
} from "../helpers/datetime.helpers";

function sumArrays(arrays) {
  const arr = [];
  let maxLength = -1;
  arrays.forEach((ar) => {
    if (ar.length > maxLength) {
      maxLength = ar.length;
    }
  });

  for (let i = 0; i < maxLength; i++) {
    let sum = 0;
    arrays.forEach((ar) => {
      if (i < ar.length) {
        sum += ar[i];
      }
    });
    arr.push(sum);
  }
  return arr;
}

function calculatePercentChange(oldValue, newValue) {
  if (oldValue <= 0) {
    if (newValue <= 0) {
      return 0;
    }
    return newValue * 100;
  }

  return Math.floor(((newValue - oldValue) / oldValue) * 100);
}

function normalizeCallStats(startDate, endDate, callStats) {
  const stats = [];
  let statForDate = startDate;

  while (statForDate.getTime() <= endDate.getTime()) {
    const isoDate = `${statForDate.getFullYear()}-${(statForDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${statForDate.getDate().toString().padStart(2, "0")}`;

    let stat = callStats.find((s) => s.callDate === isoDate);
    if (!stat) {
      stat = {
        callDate: isoDate,
        allCalls: 0,
        connectedCalls: 0,
        inboundCalls: 0,
        outboundCalls: 0,
        missedCalls: 0,
        failedCalls: 0,
      };
    }

    stats.push(stat);
    statForDate = addDays(statForDate, 1);
  }

  return stats;
}

function createStatsState(
  currentStats,
  previousStats,
  currentPeriod,
  lastPeriod
) {
  const current = normalizeCallStats(
    currentPeriod.startDate,
    currentPeriod.endDate,
    currentStats
  );
  const previous = normalizeCallStats(
    lastPeriod.startDate,
    lastPeriod.endDate,
    previousStats
  );

  const thisPeriod_allCalls = current.map((stat) => stat.allCalls);
  const thisPeriod_connectedCalls = current.map((stat) => stat.connectedCalls);
  const thisPeriod_inboundCalls = current.map((stat) => stat.inboundCalls);
  const thisPeriod_outboundCalls = current.map((stat) => stat.outboundCalls);
  const thisPeriod_missedCalls = current.map((stat) => stat.missedCalls);
  const thisPeriod_failedCalls = current.map((stat) => stat.failedCalls);

  const thisPeriod_AllCallsCount = thisPeriod_allCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const thisPeriod_ConnectedCallsCount = thisPeriod_connectedCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const thisPeriod_InboundCallsCount = thisPeriod_inboundCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const thisPeriod_OutboundCallsCount = thisPeriod_outboundCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const thisPeriod_MissedCallsCount = thisPeriod_missedCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const thisPeriod_FailedCallsCount = thisPeriod_failedCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );

  // calculate previous week summary
  const lastPeriod_allCalls = previous.map((stat) => stat.allCalls);
  const lastPeriod_connectedCalls = previous.map((stat) => stat.connectedCalls);
  const lastPeriod_inboundCalls = previous.map((stat) => stat.inboundCalls);
  const lastPeriod_outboundCalls = previous.map((stat) => stat.outboundCalls);
  const lastPeriod_missedCalls = previous.map((stat) => stat.missedCalls);
  const lastPeriod_failedCalls = previous.map((stat) => stat.failedCalls);

  const lastPeriod_AllCallsCount = lastPeriod_allCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const lastPeriod_ConnectedCallsCount = lastPeriod_connectedCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const lastPeriod_InboundCallsCount = lastPeriod_inboundCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const lastPeriod_OutboundCallsCount = lastPeriod_outboundCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const lastPeriod_MissedCallsCount = lastPeriod_missedCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const lastPeriod_FailedCallsCount = lastPeriod_failedCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );

  return {
    allCalls: thisPeriod_allCalls,
    connectedCalls: thisPeriod_connectedCalls,
    inboundCalls: thisPeriod_inboundCalls,
    outboundCalls: thisPeriod_outboundCalls,
    missedCalls: thisPeriod_missedCalls,
    failedCalls: thisPeriod_failedCalls,

    allCallsCount: thisPeriod_AllCallsCount,
    connectedCallsCount: thisPeriod_ConnectedCallsCount,
    inboundCallsCount: thisPeriod_InboundCallsCount,
    outboundCallsCount: thisPeriod_OutboundCallsCount,
    missedCallsCount: thisPeriod_MissedCallsCount,
    failedCallsCount: thisPeriod_FailedCallsCount,

    deltaAllCalls: calculatePercentChange(
      lastPeriod_AllCallsCount,
      thisPeriod_AllCallsCount
    ),
    deltaConnectedCalls: calculatePercentChange(
      lastPeriod_ConnectedCallsCount,
      thisPeriod_ConnectedCallsCount
    ),
    deltaInboundCalls: calculatePercentChange(
      lastPeriod_InboundCallsCount,
      thisPeriod_InboundCallsCount
    ),
    deltaOutboundCalls: calculatePercentChange(
      lastPeriod_OutboundCallsCount,
      thisPeriod_OutboundCallsCount
    ),
    deltaMissedCalls: calculatePercentChange(
      lastPeriod_MissedCallsCount,
      thisPeriod_MissedCallsCount
    ),
    deltaFailedCalls: calculatePercentChange(
      lastPeriod_FailedCallsCount,
      thisPeriod_FailedCallsCount
    ),
  };
}

function removeLastItem(arr) {
  if (!arr || arr.length <= 0) {
    return arr;
  }

  arr.pop();
  return arr;
}

function patchStatsState(currentPeriod, previousPeriod, patch) {
  let thisPeriod = {
    ...currentPeriod,
    allCalls: [...removeLastItem(currentPeriod.allCalls), patch.allCalls],
    connectedCalls: [
      ...removeLastItem(currentPeriod.connectedCalls),
      patch.connectedCalls,
    ],
    inboundCalls: [
      ...removeLastItem(currentPeriod.inboundCalls),
      patch.inboundCalls,
    ],
    outboundCalls: [
      ...removeLastItem(currentPeriod.outboundCalls),
      patch.outboundCalls,
    ],
    missedCalls: [
      ...removeLastItem(currentPeriod.missedCalls),
      patch.missedCalls,
    ],
    failedCalls: [
      ...removeLastItem(currentPeriod.failedCalls),
      patch.failedCalls,
    ],
  };

  const thisPeriod_AllCallsCount = thisPeriod.allCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const thisPeriod_ConnectedCallsCount = thisPeriod.connectedCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const thisPeriod_InboundCallsCount = thisPeriod.inboundCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const thisPeriod_OutboundCallsCount = thisPeriod.outboundCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const thisPeriod_MissedCallsCount = thisPeriod.missedCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const thisPeriod_FailedCallsCount = thisPeriod.failedCalls.reduce(
    (acc, curr) => acc + curr,
    0
  );

  thisPeriod = {
    ...thisPeriod,
    allCallsCount: thisPeriod_AllCallsCount,
    connectedCallsCount: thisPeriod_ConnectedCallsCount,
    inboundCallsCount: thisPeriod_InboundCallsCount,
    outboundCallsCount: thisPeriod_OutboundCallsCount,
    missedCallsCount: thisPeriod_MissedCallsCount,
    failedCallsCount: thisPeriod_FailedCallsCount,
    deltaAllCalls: calculatePercentChange(
      previousPeriod.allCallsCount,
      thisPeriod_AllCallsCount
    ),
    deltaConnectedCalls: calculatePercentChange(
      previousPeriod.connectedCallsCount,
      thisPeriod_ConnectedCallsCount
    ),
    deltaInboundCalls: calculatePercentChange(
      previousPeriod.inboundCallsCount,
      thisPeriod_InboundCallsCount
    ),
    deltaOutboundCalls: calculatePercentChange(
      previousPeriod.outboundCallsCount,
      thisPeriod_OutboundCallsCount
    ),
    deltaMissedCalls: calculatePercentChange(
      previousPeriod.missedCallsCount,
      thisPeriod_MissedCallsCount
    ),
    deltaFailedCalls: calculatePercentChange(
      previousPeriod.failedCallsCount,
      thisPeriod_FailedCallsCount
    ),
  };

  return thisPeriod;
}

const defaultCallStats = Object.freeze({
  allCalls: [],
  connectedCalls: [],
  inboundCalls: [],
  outboundCalls: [],
  missedCalls: [],
  failedCalls: [],

  allCallsCount: 0,
  connectedCallsCount: 0,
  inboundCallsCount: 0,
  outboundCallsCount: 0,
  missedCallsCount: 0,
  failedCallsCount: 0,

  deltaAllCalls: 0,
  deltaConnectedCalls: 0,
  deltaInboundCalls: 0,
  deltaOutboundCalls: 0,
  deltaMissedCalls: 0,
  deltaFailedCalls: 0,
});

const callStatsModel = {
  // States
  CallTrackingSource: {
    thisWeekStats: defaultCallStats,
    lastWeekStats: defaultCallStats,
    thisMonthStats: defaultCallStats,
    lastMonthStats: defaultCallStats,
  },
  CloudPhoneSource: {
    thisWeekStats: defaultCallStats,
    lastWeekStats: defaultCallStats,
    thisMonthStats: defaultCallStats,
    lastMonthStats: defaultCallStats,
  },
  LineForwardSource: {
    thisWeekStats: defaultCallStats,
    lastWeekStats: defaultCallStats,
    thisMonthStats: defaultCallStats,
    lastMonthStats: defaultCallStats,
  },
  IvrFlowSource: {
    thisWeekStats: defaultCallStats,
    lastWeekStats: defaultCallStats,
    thisMonthStats: defaultCallStats,
    lastMonthStats: defaultCallStats,
  },

  // Actions
  setCallTrackingSource: action((state, payload) => {
    state.CallTrackingSource = payload;
  }),

  setCloudPhoneSource: action((state, payload) => {
    state.CloudPhoneSource = payload;
  }),

  setLineForwardSource: action((state, payload) => {
    state.LineForwardSource = payload;
  }),

  setIvrFlowSource : action((state, payload) => {
    console.log("Dashboard Stats Redux : ", payload)
    state.IvrFlowSource = payload;
  }),

  setThisWeekStats: action((state, payload) => {
    state.thisWeekStats = payload;
  }),

  setLastWeekStats: action((state, payload) => {
    state.lastWeekStats = payload;
  }),

  setThisMonthStats: action((state, payload) => {
    state.thisMonthStats = payload;
  }),
  setLastMonthStats: action((state, payload) => {
    state.lastMonthStats = payload;
  }),

  AllSource: computed((state) => {
    const { CallTrackingSource, CloudPhoneSource, LineForwardSource , IvrFlowSource} = state;
    const sources = [CallTrackingSource, CloudPhoneSource, LineForwardSource , IvrFlowSource];
    const statKeys = [
      "thisWeekStats",
      "lastWeekStats",
      "thisMonthStats",
      "lastMonthStats",
    ];

    let mergedData = {};

    statKeys.forEach((key) => {
      const thisPeriod = {
        allCalls: sumArrays(sources.map((s) => s[key].allCalls)),
        connectedCalls: sumArrays(sources.map((s) => s[key].connectedCalls)),
        inboundCalls: sumArrays(sources.map((s) => s[key].inboundCalls)),
        outboundCalls: sumArrays(sources.map((s) => s[key].outboundCalls)),
        missedCalls: sumArrays(sources.map((s) => s[key].missedCalls)),
        failedCalls: sumArrays(sources.map((s) => s[key].failedCalls)),
      };
      thisPeriod.allCallsCount = thisPeriod.allCalls.reduce(
        (acc, curr) => acc + curr,
        0
      );
      thisPeriod.connectedCallsCount = thisPeriod.connectedCalls.reduce(
        (acc, curr) => acc + curr,
        0
      );
      thisPeriod.inboundCallsCount = thisPeriod.inboundCalls.reduce(
        (acc, curr) => acc + curr,
        0
      );
      thisPeriod.outboundCallsCount = thisPeriod.outboundCalls.reduce(
        (acc, curr) => acc + curr,
        0
      );
      thisPeriod.missedCallsCount = thisPeriod.missedCalls.reduce(
        (acc, curr) => acc + curr,
        0
      );
      thisPeriod.failedCallsCount = thisPeriod.failedCalls.reduce(
        (acc, curr) => acc + curr,
        0
      );

      thisPeriod.deltaAllCalls =
        sources
          .map((s) => s[key].deltaAllCalls)
          .reduce((acc, curr) => acc + curr, 0) / 4;

      thisPeriod.deltaConnectedCalls =
        sources
          .map((s) => s[key].deltaConnectedCalls)
          .reduce((acc, curr) => acc + curr, 0) / 4;

      thisPeriod.deltaInboundCalls =
        sources
          .map((s) => s[key].deltaInboundCalls)
          .reduce((acc, curr) => acc + curr, 0) / 4;

      thisPeriod.deltaOutboundCalls =
        sources
          .map((s) => s[key].deltaOutboundCalls)
          .reduce((acc, curr) => acc + curr, 0) / 4;

      thisPeriod.deltaMissedCalls =
        sources
          .map((s) => s[key].deltaMissedCalls)
          .reduce((acc, curr) => acc + curr, 0) / 4;

      thisPeriod.deltaFailedCalls =
        sources
          .map((s) => s[key].deltaFailedCalls)
          .reduce((acc, curr) => acc + curr, 0) / 4;

      mergedData = {
        ...mergedData,
        [key]: thisPeriod,
      };
    });

    console.log({ mergedData });

    return mergedData;
  }),

  // Thunks
  updateLiveData: thunk((action, payload, helpers) => {
    const todayDate = today();
    // const monday = firstMondayOfWeek(todayDate);
    // const lastMonday = addDays(monday, -7);
    // const lastSunday = addDays(monday, -1);

    const { CallTrackingSource, CloudPhoneSource, LineForwardSource , IvrFlowSource } =
      helpers.getState();

    const stats = normalizeCallStats(todayDate, todayDate, payload.current);
    var stat = stats[0];

    // let source = {};
    // if (payload.source === "CallTracking") {
    //   source = CallTrackingSource;
    // } else if (payload.source === "CloudPhone") {
    //   source = CloudPhoneSource;
    // } else if (payload.source === "LineForward") {
    //   source = LineForwardSource;
    // } else if(payload.source === "IvrFlow"){
    //   source = IvrFlowSource;
    // }
    let source = {};
    if (payload.source === "CallTracking") {
      source = IvrFlowSource;
    } else if (payload.source === "CloudPhone") {
      source = IvrFlowSource;
    } else if (payload.source === "LineForward") {
      source =IvrFlowSource;
    } else if(payload.source === "IvrFlow"){
      source = IvrFlowSource;
    }

    const { thisWeekStats, thisMonthStats, lastWeekStats, lastMonthStats } =
      source;

    // replace last item of weekly & month stats
    const updatedWeeklyStat = patchStatsState(
      thisWeekStats,
      lastWeekStats,
      stat
    );
    const updatedMonthlyStat = patchStatsState(
      thisMonthStats,
      lastMonthStats,
      stat
    );

    if (payload.source === "LineForward") {
      action.setLineForwardSource({
        ...LineForwardSource,
        thisWeekStats: updatedWeeklyStat,
        thisMonthStats: updatedMonthlyStat,
      });
    } else if (payload.source === "CallTracking") {
      action.setCallTrackingSource({
        ...CallTrackingSource,
        thisWeekStats: updatedWeeklyStat,
        thisMonthStats: updatedMonthlyStat,
      });
    } else if (payload.source === "CloudPhone") {
      action.setCloudPhoneSource({
        ...CloudPhoneSource,
        thisWeekStats: updatedWeeklyStat,
        thisMonthStats: updatedMonthlyStat,
      });
    }else if (payload.source === "IvrFlow") {
      action.setIvrFlowSource({
        ...IvrFlowSource,
        thisWeekStats: updatedWeeklyStat,
        thisMonthStats: updatedMonthlyStat,
      });
    }
  }),

  updateThisWeekStats: thunk((action, payload, helpers) => {
    const { CallTrackingSource, CloudPhoneSource, LineForwardSource , IvrFlowSource} =
      helpers.getState();
    const todayDate = today();
    const monday = firstMondayOfWeek(todayDate);
    const lastMonday = addDays(monday, -7);
    const lastSunday = addDays(monday, -1);

    const state = createStatsState(
      payload.current,
      payload.previous,
      {
        startDate: monday,
        endDate: todayDate,
      },
      {
        startDate: lastMonday,
        endDate: lastSunday,
      }
    );

    if (payload.source === "LineForward") {
      action.setLineForwardSource({
        ...LineForwardSource,
        thisWeekStats: state,
      });
    } else if (payload.source === "CallTracking") {
      action.setCallTrackingSource({
        ...CallTrackingSource,
        thisWeekStats: state,
      });
    } else if (payload.source === "CloudPhone") {
      action.setCloudPhoneSource({
        ...CloudPhoneSource,
        thisWeekStats: state,
      });
    } else if(payload.source === "IvrFlow"){
      action.setIvrFlowSource({
        ...IvrFlowSource,
        thisWeekStats: state,
      });
    }
  }),

  updateLastWeekStats: thunk((action, payload, helpers) => {
    const { CallTrackingSource, CloudPhoneSource, LineForwardSource , IvrFlowSource } =
      helpers.getState();
    const todayDate = today();
    const monday = firstMondayOfWeek(todayDate);
    const lastMonday = addDays(monday, -7);
    const lastSunday = addDays(monday, -1);
    const secondLastMonday = addDays(lastMonday, -7);
    const secondLastSunday = addDays(lastSunday, -7);

    const state = createStatsState(
      payload.current,
      payload.previous,
      {
        startDate: lastMonday,
        endDate: lastSunday,
      },
      {
        startDate: secondLastMonday,
        endDate: secondLastSunday,
      }
    );

    if (payload.source === "LineForward") {
      action.setLineForwardSource({
        ...LineForwardSource,
        lastWeekStats: state,
      });
    } else if (payload.source === "CallTracking") {
      action.setCallTrackingSource({
        ...CallTrackingSource,
        lastWeekStats: state,
      });
    } else if (payload.source === "CloudPhone") {
      action.setCloudPhoneSource({
        ...CloudPhoneSource,
        lastWeekStats: state,
      });
    } else if(payload.source === "IvrFlow"){
      action.setIvrFlowSource({
        ...IvrFlowSource,
        lastWeekStats: state,
      });
    }
  }),

  updateThisMonthStats: thunk((action, payload, helpers) => {
    const { CallTrackingSource, CloudPhoneSource, LineForwardSource  , IvrFlowSource} =
      helpers.getState();

    const todayDate = today();
    const firstDay = firstDayOfMonth(todayDate);

    const lastMonthStart = firstDayOfLastMonth(todayDate);
    const lastMonthEnd = lastDayOfMonth(todayDate);
    // console.log("first day of month :", firstDay);
    // console.log("last month start :", lastMonthStart);
    // console.log("last month end :", lastMonthEnd);
    // console.log("today date :", todayDate);
    const state = createStatsState(
      payload.current,
      payload.previous,
      {
        startDate: firstDay,
        endDate: todayDate,
      },
      {
        startDate: lastMonthStart,
        endDate: lastMonthEnd,
      }
    );

    if (payload.source === "LineForward") {
      action.setLineForwardSource({
        ...LineForwardSource,
        thisMonthStats: state,
      });
    } else if (payload.source === "CallTracking") {
      action.setCallTrackingSource({
        ...CallTrackingSource,
        thisMonthStats: state,
      });
    } else if (payload.source === "CloudPhone") {
      action.setCloudPhoneSource({
        ...CloudPhoneSource,
        thisMonthStats: state,
      });
    } else if(payload.source === "IvrFlow"){
      action.setIvrFlowSource({
        ...IvrFlowSource,
        thisMonthStats: state,
      });
    }
  }),
  updateLastMonthStats: thunk((action, payload, helpers) => {
    const { CallTrackingSource, CloudPhoneSource, LineForwardSource , IvrFlowSource } =
      helpers.getState();

    const todayDate = today();

    const lastMonthStart = firstDayOfLastMonth(todayDate);
    const lastMonthEnd = lastDayOfMonth(todayDate);

    const secondLastMonthStart = firstDayOfLastMonth(lastMonthStart);
    const secondLastMonthEnd = lastDayOfMonth(lastMonthStart);

    const state = createStatsState(
      payload.current,
      payload.previous,
      {
        startDate: lastMonthStart,
        endDate: lastMonthEnd,
      },
      {
        startDate: secondLastMonthStart,
        endDate: secondLastMonthEnd,
      }
    );

    if (payload.source === "LineForward") {
      action.setLineForwardSource({
        ...LineForwardSource,
        lastMonthStats: state,
      });
    } else if (payload.source === "CallTracking") {
      action.setCallTrackingSource({
        ...CallTrackingSource,
        lastMonthStats: state,
      });
    } else if (payload.source === "CloudPhone") {
      action.setCloudPhoneSource({
        ...CloudPhoneSource,
        lastMonthStats: state,
      });
    } else if(payload.source === "IvrFlow"){
      action.setIvrFlowSource({
        ...IvrFlowSource,
        lastMonthStats: state,
      });
    }
  }),
};

export default callStatsModel;
