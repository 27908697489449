export function today() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

export function firstMondayOfWeek(date) {
  const newDate = new Date(date.getTime());
  const day = newDate.getDay();
  const diff = newDate.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(newDate.setDate(diff));
}

export function addDays(date, days) {
  const newDate = new Date(date.getTime());
  return new Date(newDate.setDate(date.getDate() + days));
}

export function lastDayOfMonth(date) {
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return lastDay;
}

export function firstDayOfLastMonth(date) {
  const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
  return new Date(lastDay.getFullYear(), lastDay.getMonth(), 1);
}

export function firstDayOfMonth(date) {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return firstDay;
}
