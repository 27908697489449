import { createSlice } from '@reduxjs/toolkit'

export const fileimageuploadslice = createSlice({
    name: 'company_user',
    initialState: {
        value: {},
    },
    reducers: {
        incrementSelectedUser: (state, { payload }) => {
            state.value = { ...payload }
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementSelectedUser } = fileimageuploadslice.actions

export default fileimageuploadslice.reducer