import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import axios from "axios";
import { REACT_APP_BASE_URL } from "src/utils/axios";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { incrementuserslice } from "src/redux/User_status";
export function StatusComponent({ setStatus }) {
    const { user } = useSelector((state) => state.user);
    const reduxToken = localStorage.getItem('redux-token')
    const authID = localStorage.getItem("authID")
    const [data, setData] = useState([])
    const [user_status, setUserStatus] = useState("available")
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        get_status()
    }, [])
    const get_status = () => {
        axios({
            method: "GET",
            url: `${REACT_APP_BASE_URL}/api/user/status`,
            headers: {
                "Content-Type": "application/json",
                "authorization": localStorage.getItem('redux-token'),
                "jwt-authorization": localStorage.getItem('jwtToken')
            },
            data: {
                "authId": authID,
                "userId": user._id

            }
        }).then((res) => {
            //console.log("response", res.data);
            const response = res.data.data.data;
            if (response.length > 0) {
                const status = response[0].status;
                setData(response)
                setStatus(status)
                setUserStatus(status)

            }
            setLoading(false)
        }).catch((err) => {
            //console.log("error_response", err.response.data)
            setLoading(false)
        })
    }

    //console.log("user_status", user_status)
    dispatch(incrementuserslice(user_status))
    const post_status = (value) => {
        setUserStatus(value);
        setStatus(value);
        axios({
            method: data.length !== 0 ? "PATCH" : "POST",
            url: data.length !== 0 ? `${REACT_APP_BASE_URL}/api/user/status/${data[0]._id}` : `${REACT_APP_BASE_URL}/api/user/status`,
            headers: {
                "Content-Type": "application/json",
                "jwt-authorization": localStorage.getItem('jwtToken')
            },
            data: {
                "authId": authID,
                "userId": user._id,
                "status": value
            }
        }).then((res) => {
            //console.log("response", res.data)
        }).catch((err) => {
            //console.log("error_response", err.response.data)
        })
    }
    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", backgroundColor: "#fff" }} className="center-col">
            {loading === true ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                    <CircularProgress color="primary" />
                </div>
            ) : (
                <div style={{ width: "90%", display: "flex", alignSelf: "center", marginTop: 20, flexDirection: "column" }}>
                    <div style={{ color: "#0046BB", fontSize: 14, fontWeight: "600" }}>
                        Default
                    </div>
                    <div style={{ width: "100%", height: 140, backgroundColor: "#FAFAFA", marginTop: 20, borderRadius: 5, boxShadow: "1px 1px 1px 1px lightgrey", marginBottom: 20, }}>
                        <Button style={{ width: "100%", height: "25%", borderRadius: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} onClick={() => post_status("available")}>
                            <div style={{ display: "flex", alignItems: "center", }}>
                                <div style={{ width: 20, height: 20, borderRadius: 15, backgroundColor: "#29BA05", }} />
                                <div style={{ color: "#000", fontSize: 13, marginLeft: 10, fontWeight: "normal" }}>
                                    Available
                                </div>
                            </div>
                            {user_status === "available" ? (
                                <div style={{ height: 20, width: 20, borderRadius: 15, border: "2px solid #0046BB", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: "#0046BB" }} />
                                </div>
                            ) : (
                                <div style={{ height: 20, width: 20, borderRadius: 15, border: "2px solid lightgrey" }} />
                            )}
                        </Button>
                        <Button style={{ width: "100%", height: "25%", borderRadius: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} onClick={() => post_status("away")}>
                            <div style={{ display: "flex", alignItems: "center", }}>
                                <ArrowBackOutlinedIcon style={{ color: "#D28B00", width: 20, height: 20 }} />
                                <div style={{ color: "#000", fontSize: 13, marginLeft: 10, fontWeight: "normal" }}>
                                    Away
                                </div>
                            </div>
                            {user_status === "away" ? (
                                <div style={{ height: 20, width: 20, borderRadius: 15, border: "2px solid #0046BB", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: "#0046BB" }} />
                                </div>
                            ) : (
                                <div style={{ height: 20, width: 20, borderRadius: 15, border: "2px solid lightgrey" }} />
                            )}
                        </Button>
                        <Button style={{ width: "100%", height: "25%", borderRadius: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} onClick={() => post_status("busy")}>
                            <div style={{ display: "flex", alignItems: "center", }}>
                                <div style={{ width: 20, height: 20, borderRadius: 15, backgroundColor: "#BC0505", }} />
                                <div style={{ color: "#000", fontSize: 13, marginLeft: 10, fontWeight: "normal" }}>
                                    Busy
                                </div>
                            </div>
                            {user_status === "busy" ? (
                                <div style={{ height: 20, width: 20, borderRadius: 15, border: "2px solid #0046BB", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: "#0046BB" }} />
                                </div>
                            ) : (
                                <div style={{ height: 20, width: 20, borderRadius: 15, border: "2px solid lightgrey" }} />
                            )}
                        </Button>
                        <Button style={{ width: "100%", height: "25%", borderRadius: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} onClick={() => post_status("Do Not Disturb")}>
                            <div style={{ display: "flex", alignItems: "center", }}>
                                <NotificationsOffOutlinedIcon style={{ color: "#BC0505", width: 20, height: 20, }} />
                                <div style={{ color: "#000", fontSize: 13, marginLeft: 10, fontWeight: "normal" }}>
                                    Do not disturb
                                </div>
                            </div>
                            {user_status === "Do Not Disturb" ? (
                                <div style={{ height: 20, width: 20, borderRadius: 15, border: "2px solid #0046BB", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: "#0046BB" }} />
                                </div>
                            ) : (
                                <div style={{ height: 20, width: 20, borderRadius: 15, border: "2px solid lightgrey" }} />
                            )}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}