import { createSlice } from '@reduxjs/toolkit'

export const authidSlice = createSlice({
    name: 'auth_id',
    initialState: {
        value: '',
    },
    reducers: {
        incrementauthid: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementauthid } = authidSlice.actions

export default authidSlice.reducer