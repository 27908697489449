import { Button, CircularProgress } from '@mui/material';
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { REACT_APP_BASE_URL } from 'src/utils/axios'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutline";

function BlacklistComponent({ setAddBlaclist }) {
  let authId = localStorage.getItem('authID');
  //const localStorage.getItem('redux-token') = localStorage.getItem('redux-token');
  const [blacklist, setBlacklist] = useState([])
  const [add_number, setAddNumber] = useState(false)
  const [id, setId] = useState("");
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const get_blacklist_number = () => {
    axios({
        method: "GET",
        url: `${REACT_APP_BASE_URL}/api/blocklist?authId=${authId}`,
        headers: {
            authorization: localStorage.getItem('redux-token'),
            'jwt-authorization': localStorage.getItem('jwtToken')
        }
    }).then((res) => {
        const response = res.data.data.data;
        //console.log("blacklist_response", response)
        setBlacklist(response)
        setLoading(false)
    }).catch((err) => {
        //console.log("error_response", err.response.data)
        setLoading(false)
    })
  }

  useEffect(() => {
    get_blacklist_number();
  }, [])

  const delete_blacklist = (id) => {
    setId(id);
    setVisible(true)
    axios({
        method: "DELETE",
        url: `${REACT_APP_BASE_URL}/api/blocklist/${id}`,
        headers: {
            authorization: localStorage.getItem('redux-token')
        }
    }).then((res) => {
        const response = res.data;
        //console.log("response_data", response);
        axios({
            method: "GET",
            url: `${REACT_APP_BASE_URL}/api/blocklist?authId=${authId}`,
            headers: {
                authorization: localStorage.getItem('redux-token'),
                'jwt-authorization': localStorage.getItem('jwtToken')
            }
        }).then((res1) => {
            const response1 = res1.data.data.data;
            //console.log("blacklist_data", response);
            setBlacklist(response1)
            setVisible(false)
        }).catch((err) => {
            //console.log("error_response", err.response.data)
            setVisible(false)
        })
    }).catch((err) => {
        //console.log("error_response", err.response.data);
        setVisible(false)
    })
  }
  return (
    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", backgroundColor: "#fff" }} className="center-col">
      <div style={{ width: "100%"}}>
        <Button style={{ width: "100%", height: 50, borderRadius: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #0046BB" }} onClick={() => setAddBlaclist(true)}>
            <div style={{ height: "100%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                <div style={{ width: 30, height: 30, backgroundColor: "#088DD8", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <PersonAddAlt1Icon style={{ color: "#fff", width: 18, height: 18, }}/>
                </div>
                <div style={{ color: "#000", marginLeft: 15, fontSize: 15, fontWeight: "500"}}>
                    Add Number
                </div>
            </div>
            <ArrowForwardIosIcon style={{ color: "#0046BB" }} />
        </Button>
        {loading === true ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                <CircularProgress color='primary' />
            </div>
        ): (
            <div style={{ marginTop: 5, width: "100%"}}>
            {blacklist.map((value, index) => (
                <div style={{ width: "100%", height: 50, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} key={index}>
                    <div style={{ display: "flex", flexDirection: "row", height: "100%", alignItems: "center"}}>
                        <img src={`https://flagcdn.com/w80/in.png`} style={{ width: 25, height: 15, marginLeft: 10 }}/>
                        <div style={{ color: "#000", fontSize: 13, fontWeight: "bold", marginLeft: 10 }}>
                            {value.number}
                        </div>
                    </div>
                    {id === value._id && visible === true ? (
                        <CircularProgress color="primary" style={{ width: 20, height: 20, marginRight: 10 }}/>
                    ): (
                        <Button style={{ marginRight: 10, width: 30, height: 30, borderRadius: 20, minWidth: 20, display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => delete_blacklist(value._id)}>
                            <DeleteOutlinedIcon style={{ color: "red", width: 20, height: 20 }} />
                        </Button>
                    )}
                </div>
            ))}
        </div>
        )}
        </div>
    </div>
  )
}

export default BlacklistComponent