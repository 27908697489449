import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from '../../../utils/axios';

//
import { dispatch } from '../../store';

import {showSnackbar} from '../snackbar'


// ----------------------------------------------------------------------


const initialState= {
    isLoading: false,
    error: null,
    numberData: {},
}

const cancelledNumberSlice = createSlice({
    name: 'cancelledNumber',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            
        },
        
        // GET NUMBER SUCCESS
        getCancelledNumberSuccess(state, action) {
          state.numberData = action.payload;
          state.isLoading = false;
          
          
        },

      


    }
})


export const selectAllNumbers = (state) => state.cancelledNumber;

export default cancelledNumberSlice.reducer

// Actions

export function getCancelledNumbers(page, rowsPerPage) {
    console.log('redux cancel number', page)
   
    return async () => {
      dispatch(cancelledNumberSlice.actions.startLoading());
      try {
        const response = await axios.get(`/getAllCancel?page=${page}&size=${rowsPerPage}`);
          dispatch(cancelledNumberSlice.actions.getCancelledNumberSuccess(response.data));
          console.log('redux cancel number', response)
      } catch (error) {
        dispatch(cancelledNumberSlice.actions.hasError(error));
        
      }
    };
  }