import { action, thunk } from "easy-peasy";
import axios from "axios";
import { baseUrl } from "./../utils/axios";

const inboxStore = {
  request: {
    numbers: null,
    caller: null,
    forwardTo: null,
    callStatus: ["completed", "canceled", "missed", "failed"],
    callTypes: ["inbound", "outbound"],
    callTags: null,
    source: ["CallTracking", "CloudPhone", "LineForward"],
    page: 1,
    pageSize: 20,
  },

  response: {
    count: 0,
    data: [],
  },

  setRequest: action((state, payload) => {
    state.request = payload;
  }),

  setResponse: action((state, payload) => {
    state.response = payload;
  }),

  getInbox: thunk(async (action, payload, helper) => {
    const { request } = helper.getState();
    const api_token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      authorization: api_token,
    };

    const requestData = payload ? payload : request;
    if (payload) {
      action.setRequest(payload);
    }

    const response = await axios.post(`${baseUrl}/inbox/all`, requestData, {
      headers,
    });

    action.setResponse(response.data);
  }),
};

export default inboxStore;
