import { createSlice } from '@reduxjs/toolkit'

export const userdatashowSlice = createSlice({
    name: 'user_data',
    initialState: {
        value: {},
    },
    reducers: {
        incrementUserdatashow: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementUserdatashow } = userdatashowSlice.actions

export default userdatashowSlice.reducer