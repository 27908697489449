import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
// utils
import axios, { REACT_APP_BASE_URL  } from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { incrementToken } from '../redux/slices/token';

import {
  useDispatch
} from 'react-redux'
import {
  getMe
 } from 'src/redux/slices/whatsapp/whatsapp'

 import {getuserRolesID, currentRoleData} from 'src/redux/slices/profile/role'


// ----------------------------------------------------------------------

const isAuthenticatedLocal = localStorage.getItem('isAuthenticated');
const isInitializedLocal = localStorage.getItem('isInitialized');



const initialState = {
  isAuthenticated: isAuthenticatedLocal,
  isInitialized: isInitializedLocal,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, isAuthenticated, isInitialized } = action.payload;

    // return {
    //   ...state,
    //   isAuthenticated: true,
    //   isInitialized: true,
    //   user,
    // };
    return {
      ...state,
      isAuthenticated,
      isInitialized,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {

  const [state, dispatch] = useReducer(reducer, initialState);


  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  const login = async (email, password) => {
  
    let result
    const data = { email_address: email, password };
    localStorage.clear()

    const response = await axios.post('/login', data);
    const accessToken = response.data.data.token
    const jwtToken = response.data.data.jwt_token
    console.log('login response', response)
    let user
    localStorage.setItem('redux-token', accessToken);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('jwtToken', jwtToken)
    if (response.data.data.whatsapp_details.token !== undefined &&  response.data.data.whatsapp_details.token !== null) {
      console.log('login whatsapp', response.data.data.whatsapp_details)
      localStorage.setItem('whatsappTokenAccess', response.data.data.whatsapp_details.token.access);
      localStorage.setItem('whatsappTokenRefresh', response.data.data.whatsapp_details.token.refresh)
      localStorage.setItem('whatsAppUser', JSON.stringify(response.data.data.whatsapp_details.user))
    }
    dispatch(incrementToken(accessToken))
    const config = {
      method: 'get',
      url: `${REACT_APP_BASE_URL}/api/getAuth`,
      headers: {
        'authorization': accessToken,
      }
    };
   
    const resultAuth = await axios(config)
    console.log("result in auth 168 ", resultAuth);

    if (resultAuth.data.status) {

      setCookie('vib_u_id', resultAuth.data.data._id, 1)
      const whatsappNumber = resultAuth.data.data.whatsAppNumber;
      const phoneNumberId = resultAuth.data.data.phoneNumberId;
      const isWhatsappActive = resultAuth.data.data.isWhatsappActive;
      localStorage.setItem('email', resultAuth.data.data.email);
      localStorage.setItem('FirstName', resultAuth.data.data.FirstName)
      localStorage.setItem('user_data', JSON.stringify(resultAuth.data.data));
      localStorage.setItem('is_admin', resultAuth.data.data.is_admin)
      localStorage.setItem('isWhatsappActive', isWhatsappActive)
      localStorage.setItem('whatsappNumber', whatsappNumber);
      localStorage.setItem('phoneNumberId', phoneNumberId)
      localStorage.setItem("lineForwardAppId", resultAuth.data.data.lineForwardAppId)
      localStorage.setItem("CloudPhoneAppId", resultAuth.data.data.CloudPhoneAppId)
      localStorage.setItem("IvrStudioAppId", resultAuth.data.data.IvrStudioAppId)
      localStorage.setItem("CancelNumberAppId", resultAuth.data.data.CancelNumberAppId)
      if (resultAuth.data.data.auth_id !== undefined && resultAuth.data.data.auth_id !== null && resultAuth.data.data.auth_id !== "undefined") {
        localStorage.setItem('authID', resultAuth.data.data.auth_id);
      }

      if(resultAuth.data.data.is_admin){

      }else{
        if(resultAuth.data.data.role !== null && resultAuth.data.data.role !== undefined){
          console.log('roles id', resultAuth.data.data.role)
          localStorage.setItem('roleId', resultAuth.data.data.role)
        }
      }

      if (resultAuth.data.data._id !== undefined && resultAuth.data.data._id !== null && resultAuth.data.data._id !== "undefined") {
        localStorage.setItem('userID', resultAuth.data.data._id);
      }
      localStorage.setItem("companyID", resultAuth.data.data.company_id);
      localStorage.setItem('authSecretId', resultAuth.data.data.auth_secret);
      if (resultAuth.data.data.phone !== undefined && resultAuth.data.data.phone !== null && resultAuth.data.data.phone !== "undefined") {
        localStorage.setItem("phone", resultAuth.data.data.phone)
      }
      localStorage.setItem('timeZone', resultAuth.data.data.timeZone)

      let tokenOfVibtree = 'EAAOdOeKzYdkBAAjoshNzqleILw5QveXm2swoNVCJzW0urYaxsqaT878bbfzt0y6Io7ECl4XiLs3lyZBsPZBrnyr40i2WJkQdirAi0T7hSsz6sYJ21x1scKdAZApWpH8CzzPZCJFBjUWF0ddny6OMEbmFXzUfBC28rrtVjbwoDUBpp1yVbQVB';
      localStorage.setItem("whatsappToken", tokenOfVibtree)
      if (resultAuth.data.data.whatsappToken !== undefined && resultAuth.data.data.whatsappToken !== null) {
        localStorage.setItem("whatsappToken", resultAuth.data.data.whatsappToken)
      }
      if (resultAuth.data.data.viewType !== undefined && resultAuth.data.data.viewType !== null) {
        localStorage.setItem("viewType", resultAuth.data.data.viewType)
      } else {
        localStorage.setItem("viewType", "All")
      }
      let assignedNumber = resultAuth.data.data.assignedNumber;
      let stringyfyAssignedNumber = JSON.stringify(assignedNumber);
      localStorage.setItem("assignedNumber", stringyfyAssignedNumber);
      // this code set the token in header after login 
      axios.interceptors.request.use(function (config) {
        config.headers.authorization = accessToken;
        return config;
      });
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('isInitialized', true);



    }

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        isAuthenticated: true,
        isInitialized: true,
      },
    });
    return { isAuthenticated: true };
  };

 

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {

    // setSession(null);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('isInitialized');
    const id = localStorage.getItem("user_status_id")



    localStorage.removeItem('user_status_id');

    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };