import moment from 'moment-timezone'
import {useEffect} from 'react'

export default function TimeZone({date}){
    

    const timeZoneLocal = localStorage.getItem('timeZone') ? localStorage.getItem('timeZone') : 'Asia/Kolkata'
    // console.log("Time Zone : ", timeZone)
    // const time = moment_timezone(date)
    // console.log("TimeZone : ", typeof time.tz(timeZone).format(),time.tz(timeZone).format()) 
    // const convertedTime = time.tz(timeZone).format().split("T")
    // console.log("Converted Hour : ",  convertedTime[1].split(":")[0])
    // const convertedHours = convertedTime[1].split(":")[0] ? convertedTime[1].split(":")[0] : "00"
    // console.log("Converted Minutes : ", convertedTime[1].split(":")[1])
    // const convertedMinutes =convertedTime[1].split(":")[1] ?convertedTime[1].split(":")[1] : "00"
    // const requiredTime = moment(time.tz(timeZone).format()).set({hour : parseInt(convertedHours) , minute : (parseInt(convertedMinutes))}).toDate()
    // console.log("Required Time : ", typeof requiredTime , requiredTime )


   var dateTime = moment.tz(date, timeZoneLocal).format()
   const editedText = dateTime.slice(0, -6) 

   const dateformat = new Date(editedText)

   var hour = dateformat.getHours() == 0 ? 12 : (dateformat.getHours() > 12 ? dateformat.getHours() - 12 : dateformat.getHours());
    var min = dateformat.getMinutes() < 10 ? '0' + dateformat.getMinutes() : dateformat.getMinutes();
    var ampm = dateformat.getHours() < 12 ? 'AM' : 'PM';
    var time = hour + ':' + min + ' ' + ampm;


   var b = moment.tz(date, "Do MMM YYYY , h:mm:ss", timeZoneLocal);
   var formated = b.format();
   var addingTime = moment.tz(date, "Do MMM YYYY,  h:mm:ss", timeZoneLocal).format('Z');


   const convertedTime = formated.replace(addingTime, '')

   useEffect(() => {
        if(addingTime.includes('+')){
            console.log('adding time +', date, b, addingTime,'before convert',formated, 'converted', convertedTime, '********', dateTime, editedText)
        }else
        if(addingTime.includes('-')){
            console.log('adding time -', date, b, addingTime,'bofore convert', formated, 'converted', convertedTime, '********', dateTime, editedText)
        }
   }, [addingTime])



   
    return(
        <>
        {(dateformat.toString()).substring(0, 16)}, {time}
        {/* {b.format()}
        {moment(b).format('MMMM Do YYYY, h:mm:ss a')} */}
        {/* {adding} */}
        </>
    )
}