import socketIOClient from "socket.io-client";
import { REACT_APP_BASE_URL } from "./axios";

export const socket = socketIOClient(`${REACT_APP_BASE_URL}/dropcodes`,{

    "path": "/websockets",
    "transports": ["polling","websocket"],
    "transportOptions": {
      "polling": {
          "extraHeaders": {
              "authorization": "ApiKey <Key>"
          }
      }
    }
});