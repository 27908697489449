import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from '../../utils/axios';

//
import { dispatch } from '../store';

import {showSnackbar} from './snackbar'

// ----

const initialState = {
    isLoading: false,
    error: null,
    pollyAudio: '',
}

const audioPackageSlice = createSlice({
    name: 'audioPackage',
    initialState,
    reducers: {

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // POST TEXT TO SPEACH
        postTextToSpeech(state, action) {
            state.pollyAudio = action.payload;
            state.isLoading = false;
          },

        
    }
})


export const selectAudioPackage = (state) => state.audioPackage;

export default audioPackageSlice.reducer


export function postTextToSpeech(data) {


    return async () => {
      dispatch(audioPackageSlice.actions.startLoading());
      try {
        const response = await axios.post(`/text-to-speech`, data);
          dispatch(audioPackageSlice.actions.postTextToSpeech(response.data));
          console.log('buy number redux response', response.data.status)
          if(response.data.status === true){
            dispatch(showSnackbar('Hurray.. Number Successfully Purchased'));
          }else{
            dispatch(showSnackbar('Oops.. Number Purchase Failed'));
          }
          
      } catch (error) {
          dispatch(audioPackageSlice.actions.hasError(error));
          dispatch(showSnackbar('Oops.. Number Purchase Failed'));
      }
    };
  }
