import { createSlice } from '@reduxjs/toolkit'

export const Refreshpageslice = createSlice({
    name: 'refresh_page',
    initialState: {
        value: {},
    },
    reducers: {
        incrementRefreshpage: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementRefreshpage } = Refreshpageslice.actions

export default Refreshpageslice.reducer