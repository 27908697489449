import PropTypes from 'prop-types';
// @mui
import { Box, Card, Button, Typography, Stack, Paper, Dialog, TextField, Grid, Skeleton } from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import axios from 'axios';
import axiosInstance, { baseUrl } from 'src/utils/axios';
import { useEffect } from 'react';
import { useState } from 'react';

// ----------------------------------------------------------------------

AccountBillingAddressBook.propTypes = {
  addressBook: PropTypes.array,
};

export default function AccountBillingAddressBook({ addressBook }) {
  const [editdrawer, seteditdrawer] = useState(false)
  const tokengst = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY2MzkzNTUxOCwianRpIjoiMGRmZWUwMzQtYjQ1Mi00ZmE3LThhNTktNTcxYmE1ZDAxYzYyIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnZpYnRyZWVAc3VyZXBhc3MuaW8iLCJuYmYiOjE2NjM5MzU1MTgsImV4cCI6MTk3OTI5NTUxOCwidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbIndhbGxldCJdfX0.jatUbHtWTUYTJZ9MRD-vZnw1BzfQ4PDMFTJvnz3oyj8'
  const [loader, setloader] = useState(true)
  // const authAxiosgst = axios.create({
  //   baseURL: apiUrlgst,
  //   headers: {
  //     "Authorization": `Bearer ${tokengst}`
  //   }

  // })  
  const [addr, setaadr] = useState('')
  const [phones, setphone] = useState('')
  const [names, setname] = useState('')

  const getdata = async () => {

    const res = await axiosInstance.get(`${baseUrl}/getAuth`)
    console.log('getauth', res.data.data)

    localStorage.setItem('FirstName', res.data.data.FirstName)
    localStorage.setItem('LastName', res.data.data.LastName)
    localStorage.setItem('email', res.data.data.email)
    localStorage.setItem('phone', res.data.data.phone)
    localStorage.setItem('country', res.data.data.country_allowed[0].code)
    localStorage.setItem('_id', res.data.data._id)
    localStorage.setItem('company_id', res.data.data.company_id)
    localStorage.setItem('user_logo', res.data.data.user_logo)


    //  .then((res) => {
    //       console.log(res.data.data)

    //       setfirstname(res.data.data.FirstName)
    //       setlastname(res.data.data.LastName)
    //       setemail(res.data.data.email)
    //       setphonenumber(res.data.data.phone)
    //       localStorage.setItem('FirstName', res.data.data.FirstName)
    //       localStorage.setItem('LastName', res.data.data.LastName)
    //       localStorage.setItem('email', res.data.data.email)
    //       localStorage.setItem('phone', res.data.data.phone)
    //       localStorage.setItem('country', res.data.data.country_allowed[0].code)
    //       localStorage.setItem('_id', res.data.data._id)

    //     }).catch((err) => {
    //       console.log(err)
    //     })

  }
  useEffect(() => {
    getdata()
  }, [])
  const company_id = localStorage.getItem('company_id')
  const addressapi = () => {

    axiosInstance.get(`${baseUrl}/companies/${company_id}`).then((res) => {
      console.log("vikalpaddress", res.data.data[0])
      setaadr(res.data.data[0].address)
      setphone(res.data.data[0].phone)
      setname(res.data.data[0].name)
      localStorage.setItem('aadr', res.data.data[0].address)
      localStorage.setItem('phn', res.data.data[0].phone)
      localStorage.setItem('nam', res.data.data[0].name)
      setloader(false)
    }).catch((err) => {
      console.log(err)
      setloader(false)
    })
  }
  useEffect(() => {
    addressapi()
  })
  console.log(addr)

  const addh = localStorage.getItem('aadr')
  console.log("addh", addh)
  const phn = localStorage.getItem('phn')
  const nam = localStorage.getItem('nam')
  const [address, setaddress] = useState(addh)
  const [phone, setphones] = useState(phn)
  const [name, setnames] = useState(nam)

  const handleEdit = () => {
    axiosInstance.put(`${baseUrl}/companies/${company_id}`, {
      address: address,
      phone: phone,
      name: name
    })
  }
  const skeltonLoaders = (
    <>
      <Grid container>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>


      </Grid>
    </>
  )


  return (
    <Card sx={{ p: 3 }}>
      {loader == true ? <>
        {skeltonLoaders}
      </> : <>
        <Stack spacing={3} alignItems="flex-start">
          <Typography variant="overline" sx={{ color: 'text.secondary' }}>
            Billing Info
          </Typography>
          <Paper
            // key={address.id}
            sx={{
              p: 3,
              width: 1,
              bgcolor: 'background.neutral',
            }}
          >
            {/* <Typography variant="subtitle1" gutterBottom>

            {names}
          </Typography> */}

            <Typography variant="body2" gutterBottom>
              <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                Address: &nbsp;
              </Typography>
              {addr}

            </Typography>

            <Typography variant="body2" gutterBottom>
              <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                Phone: &nbsp;
              </Typography>
              {phones}
            </Typography>

            {/* <Box sx={{ mt: 1 }}>
            <Button
              color="error"
              size="small"
              startIcon={<Iconify icon={'eva:trash-2-outline'} />}
              onClick={() => { }}
              sx={{ mr: 1 }}
            >
              Delete
            </Button>
            <Button size="small" startIcon={<Iconify icon={'eva:edit-fill'} />} onClick={() => { }}>
              Edit
            </Button>
          </Box> */}
          </Paper>
          {/* {addressBook.map((address) => (
          <Paper
            key={address.id}
            sx={{
              p: 3,
              width: 1,
              bgcolor: 'background.neutral',
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              {address.name}
            </Typography>

            <Typography variant="body2" gutterBottom>
              <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                Address: &nbsp;
              </Typography>
              {`${address.street}, ${address.city}, ${address.state}, ${address.country} ${address.zipCode}`}
            </Typography>

            <Typography variant="body2" gutterBottom>
              <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                Phone: &nbsp;
              </Typography>
              {address.phone}
            </Typography>

            <Box sx={{ mt: 1 }}>
              <Button
                color="error"
                size="small"
                startIcon={<Iconify icon={'eva:trash-2-outline'} />}
                onClick={() => {}}
                sx={{ mr: 1 }}
              >
                Delete
              </Button>
              <Button size="small" startIcon={<Iconify icon={'eva:edit-fill'} />} onClick={() => {}}>
                Edit
              </Button>
            </Box>
          </Paper>
        ))} */}

          <Button size="small" startIcon={<Iconify icon={'eva:edit-fill'} />} onClick={() => {
            seteditdrawer(true)

          }}>
            Edit address
          </Button>
        </Stack>
      </>}



      <Dialog
        open={editdrawer}
        onClose={() => seteditdrawer(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid >
          <Card sx={{ p: 3, pb: 3 }}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                // gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <Grid>
                Name
                <TextField name='Name' fullWidth value={name} onChange={(e) => setnames(e.target.value)} />
              </Grid>
              <Grid>
                Phone  <TextField name='phone' type='number' fullWidth value={phone} onChange={(e) => setphones(e.target.value)} />
              </Grid>
              <Grid >
                Address
                <TextField name='address' multiline rows={3} fullWidth value={address} onChange={(e) => setaddress(e.target.value)} />
              </Grid>
            </Box>
            <Button color='primary' variant='contained' style={{ marginLeft: "250px", marginTop: "20px" }} onClick={() => {
              handleEdit()
              seteditdrawer(false)
            }}>
              Edit
            </Button>
          </Card>

        </Grid>

      </Dialog>
    </Card>
  );
}
