import { createSlice } from '@reduxjs/toolkit'

export const Selectedvoicenumberslice = createSlice({
    name: 'selected_voicenumber',
    initialState: {
        value: '',
        country: '',
        pageLimit: 10,
        pageOffset: 0,
    },
    reducers: {
        incrementSelectedvoicenumber: (state, { payload }) => {
            state.value = payload
        },
        selectedCountryNumber: (state, { payload }) => {
            state.country = payload
        },
        selectedPageLimitNumber: (state, { payload }) => {
            state.limit = payload
        },
        selectedPageOffsetNumber: (state, { payload }) => {
            state.offset = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementSelectedvoicenumber, selectedCountryNumber, selectedPageLimitNumber, selectedPageOffsetNumber } = Selectedvoicenumberslice.actions

export default Selectedvoicenumberslice.reducer