import { createSlice } from '@reduxjs/toolkit'

export const CreatedbyidSlice = createSlice({
    name: 'created_by',
    initialState: {
        value: '',
    },
    reducers: {
        incrementcreatebyid: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementcreatebyid } = CreatedbyidSlice.actions

export default CreatedbyidSlice.reducer