import { createSlice } from '@reduxjs/toolkit'

export const fileuploadlastmodifySlice = createSlice({
    name: 'upload_last_modify',
    initialState: {
        value: {},
    },
    reducers: {
        incrementUploadlastmodify: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementUploadlastmodify } = fileuploadlastmodifySlice.actions

export default fileuploadlastmodifySlice.reducer