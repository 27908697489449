import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { Box, Grid, Card, Button, Typography, Stack, Skeleton } from '@mui/material';
//
import AccountBillingAddressBook from './AccountBillingAddressBook';
import AccountBillingPaymentMethod from './AccountBillingPaymentMethod';
import AccountBillingInvoiceHistory from './AccountBillingInvoiceHistory';
import axiosInstance, { baseUrl } from 'src/utils/axios';

// ----------------------------------------------------------------------

AccountBilling.propTypes = {
  addressBook: PropTypes.array,
  cards: PropTypes.array,
  invoices: PropTypes.array,
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default function AccountBilling({ cards, addressBook, invoices }) {
  const [open, setOpen] = useState(false);
  const [subscr, setsubscr] = useState('')
  const [loader, setloader] = useState(true)
  const { height, width } = useWindowDimensions();
  const getdata = async () => {

    const res = await axiosInstance.get(`${baseUrl}/getAuth`)
    console.log('getauth1', res.data.data)
    localStorage.setItem('customerIdInZoho', res.data.data.customerIdInZoho)
    localStorage.setItem('FirstName', res.data.data.FirstName)
    localStorage.setItem('LastName', res.data.data.LastName)
    localStorage.setItem('email', res.data.data.email)
    localStorage.setItem('phone', res.data.data.phone)
    localStorage.setItem('country', res.data.data.country_allowed[0].code)
    localStorage.setItem('_id', res.data.data._id)
    localStorage.setItem('user_logo', res.data.data.user_logo)
    setsubscr(res.data.data.subscription_type)

    console.log('getauthset', res.data.data.subscription_type)

  }
  useEffect(() => {
    getdata()
  }, [])
  const customerIdInZoho = localStorage.getItem('customerIdInZoho')
  console.log("getauthsetttt", subscr);
  const [planname, setplanname] = useState('')
  const [planstatus, setplanstatus] = useState('')
  const [plantotal, setplantotal] = useState('')
  const [planstartat, setplanstartat] = useState('')
  const [planendat, setplanendat] = useState('')
  const [plansysmbol, setplansymbol] = useState('')

  const planttrailapi = () => {

    axiosInstance.get(`${baseUrl}/users-plan?customer_id=${customerIdInZoho}`).then((res) => {
      console.log('plan trail api', res.data.data)
      setplanname(res.data.data.plan_name)
      setplanstatus(res.data.data.status)
      setplantotal(res.data.data.sub_total)
      setplanstartat(res.data.data.current_term_starts_at)
      setplanendat(res.data.data.current_term_ends_at)
      setplansymbol(res.data.data.currency_symbol)
      setloader(false)
    }).catch((err) => {
      console.log(err)
      setloader(false)
    })
  }
  const skeltonLoaders = (
    <>
      <Grid container>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>


      </Grid>
    </>
  )
  useEffect(() => {
    planttrailapi()
  }, [])

  return (
    <Grid container spacing={5} style={{ marginTop: "1%" }}>
      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          {loader == true ? <>
            {skeltonLoaders}
          </> : <>
            <Card sx={{ p: 3 }}>
              <Typography variant="overline" sx={{ mb: 3, display: 'block', color: 'text.secondary' }}>
                Your Plan
              </Typography>
              {/* <Typography variant="h4">{subscr ? subscr : "Trial"}</Typography> */}

              {width < 500 ? <>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>


                  <Typography variant="h6" style={{ fontSize: "15px" }} >{planname} {planstatus == 'trail' ? <>
                    <Typography>(</Typography>
                    {planstatus}
                    <Typography>)</Typography>
                  </> : null}</Typography>
                  {/* <div>
                <Typography variant="h4" style={{ width: "100%", display: "flex", flexDirection: "row-reverse" }}>{plansysmbol}{plantotal} </Typography>
                <div>


                  <Typography sx={{ display: 'block', color: 'text.secondary' }}>Activated On: &nbsp; {planstartat} </Typography>
                  <Typography sx={{ display: 'block', color: 'text.secondary' }}>Expiring On: &nbsp; &nbsp; {planendat} </Typography>
                </div>
              </div> */}
                </div>
                <Box
                // sx={{
                //   mt: { xs: 2, sm: 0 },
                //   position: { sm: 'absolute' },
                //   top: { sm: 24 },
                //   right: { sm: 24 },
                // }}
                >
                  <div>
                    <Typography variant="h6" style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "15px", position: "relative", top: "-20px" }}>{plansysmbol}{plantotal} </Typography>
                    <div>


                      <Typography sx={{ display: 'block', color: 'text.secondary' }}>Activated On: &nbsp; {planstartat} </Typography>
                      <Typography sx={{ display: 'block', color: 'text.secondary' }}>Expiring On: &nbsp; &nbsp; {planendat} </Typography>
                    </div>
                  </div>
                  {/* <Button size="small" color="inherit" variant="outlined" sx={{ mr: 1 }}>
                Cancel
              </Button>
              <Button size="small" variant="outlined">
                Upgrade
              </Button> */}
                </Box>
              </> : <>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>


                  <Typography variant="h4" >{planname} {planstatus == 'trail' ? <>
                    <Typography>(</Typography>
                    {planstatus}
                    <Typography>)</Typography>
                  </> : null}</Typography>
                  {/* <div>
                <Typography variant="h4" style={{ width: "100%", display: "flex", flexDirection: "row-reverse" }}>{plansysmbol}{plantotal} </Typography>
                <div>


                  <Typography sx={{ display: 'block', color: 'text.secondary' }}>Activated On: &nbsp; {planstartat} </Typography>
                  <Typography sx={{ display: 'block', color: 'text.secondary' }}>Expiring On: &nbsp; &nbsp; {planendat} </Typography>
                </div>
              </div> */}
                </div>
                <Box
                  sx={{
                    mt: { xs: 2, sm: 0 },
                    position: { sm: 'absolute' },
                    top: { sm: 24 },
                    right: { sm: 24 },
                  }}
                >
                  <div>
                    <Typography variant="h4" style={{ width: "100%", display: "flex", flexDirection: "row-reverse" }}>{plansysmbol}{plantotal} </Typography>
                    <div>


                      <Typography sx={{ display: 'block', color: 'text.secondary' }}>Activated On: &nbsp; {planstartat} </Typography>
                      <Typography sx={{ display: 'block', color: 'text.secondary' }}>Expiring On: &nbsp; &nbsp; {planendat} </Typography>
                    </div>
                  </div>
                  {/* <Button size="small" color="inherit" variant="outlined" sx={{ mr: 1 }}>
                Cancel
              </Button>
              <Button size="small" variant="outlined">
                Upgrade
              </Button> */}
                </Box>
              </>}

            </Card>
          </>

          }


          <AccountBillingPaymentMethod
            cards={cards}
            isOpen={open}
            onOpen={() => setOpen(!open)}
            onCancel={() => setOpen(false)}
          />

          <AccountBillingAddressBook addressBook={addressBook} />
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <AccountBillingInvoiceHistory invoices={invoices} />
      </Grid>
    </Grid>
  );
}
