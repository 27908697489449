import { createSlice } from '@reduxjs/toolkit'

export const fileuploadsizeSlice = createSlice({
    name: 'upload_size',
    initialState: {
        value: {},
    },
    reducers: {
        incrementUploadsize: (state, { payload }) => {
            state.value = payload 
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementUploadsize } = fileuploadsizeSlice.actions

export default fileuploadsizeSlice.reducer