import React from 'react'
import './SuccesfulModel.css'
const Successfull = () => {
    return (
        <div className="modal-background">
            <div className="modal-container">
                {/* <img class='done-icon' src={require('./Image/done_icon.png')}></img> */}
                <div className="body">
                    <span>Password Change Successfully</span>
                    <p>
                        Back to Login Page
                    </p>
                </div>
                <div className="ok-button">

                    <button >Ok</button>
                </div>
            </div>
        </div>
    )
}

export default Successfull