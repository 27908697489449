import { createSlice } from '@reduxjs/toolkit'

export const whatsappauthkeySlice = createSlice({
    name: 'whatsapp_authkey',
    initialState: {
        value: '',
    },
    reducers: {
        incrementwhatsappauthkey: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementwhatsappauthkey } = whatsappauthkeySlice.actions

export default whatsappauthkeySlice.reducer