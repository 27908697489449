import { createSlice } from '@reduxjs/toolkit'

export const assignedidSlice = createSlice({
    name: 'assigned_id',
    initialState: {
        value: '',
    },
    reducers: {
        incrementassignedid: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementassignedid } = assignedidSlice.actions

export default assignedidSlice.reducer