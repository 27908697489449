import { createSlice } from "@reduxjs/toolkit";
import { useSnackbar } from 'notistack';
// utils
import axios, { REACT_APP_BASE_URL } from '../../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    inboxViewById: {},
  
  
  }

  const inboxOnboardingSlice = createSlice({
    name: 'inboxOnboarding',
    initialState,
    reducers: {

      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },

      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
  
      },
  
      // GET
      getInboxByIdSuccess(state, action) {
        state.inboxViewById = action.payload;
        state.isLoading = false;
      },
  
   
  
    }
  })
  
  
  
  export const selectAllInboxOnboardingData = (state) => state.inboxOnboarding;
  
  
  export default inboxOnboardingSlice.reducer


  export function getInboxById(id) {
    return async () => {
      dispatch(inboxOnboardingSlice.actions.startLoading());
      try {
        const response = await axios.get(`/inbox-view/${id}`);
        dispatch(inboxOnboardingSlice.actions.getInboxByIdSuccess(response.data));
        console.log('getInboxById', response)
      } catch (error) {
        dispatch(inboxOnboardingSlice.actions.hasError(error));
  
      }
    };
  }