import { createSlice } from '@reduxjs/toolkit'
export const userAuthDataSlice = createSlice({
    name: 'userAuthData',
    initialState: {
        value: {},
    },
    reducers: {
        userAuthData: (state, { payload }) => {
            state.value = payload
        }
    },
})
// Action creators are generated for each case reducer function
export const { userAuthData } = userAuthDataSlice.actions
export default userAuthDataSlice.reducer
