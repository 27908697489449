import { createSlice } from '@reduxjs/toolkit'

export const ChannelinboxSlice = createSlice({
    name: 'inbox_channel',
    initialState: {
        value: '',
    },
    reducers: {
        incrementChannelinbox: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementChannelinbox } = ChannelinboxSlice.actions

export default ChannelinboxSlice.reducer