import { createSlice } from '@reduxjs/toolkit'

export const UserstatusSlice = createSlice({
    name: 'User_status',
    initialState: {
        value: '',
    },
    reducers: {
        incrementuserslice: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementuserslice } = UserstatusSlice.actions

export default UserstatusSlice.reducer