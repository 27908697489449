
import { createSlice } from "@reduxjs/toolkit";
import { useSnackbar } from 'notistack';
import { incrementSelectedButton } from "src/redux/Selectbutton";
import { incrementshowsnackbaroopsslice } from "src/redux/showsnackoops";
import { incrementshowsnackbarslice } from "src/redux/snackbarslice";
// utils
import axios from '../../../utils/axios';

//
import { dispatch } from '../../store';

import { showSnackbar } from '../snackbar'


// ----------------------------------------------------------------------


const initialState = {
  isLoading: false,
  error: null,
  numberData: {},
  buySingleNumberResponse: {},
}

const buyNumberSlice = createSlice({
  name: 'buyNumber',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;

    },

    // GET NUMBER SSUCCESS
    getNumberSuccess(state, action) {
      state.numberData = action.payload;
      state.isLoading = false;


    },

    // BUY SINGLE NUMBER SUCCESS
    buySingleNumberSuccess(state, action) {
      state.buySingleNumberResponse = action.payload;
      state.isLoading = false;

    }


  }
})



export const selectAllNumbers = (state) => state.buyNumber;

export default buyNumberSlice.reducer

// Actions

export function getNumbers(country, type, states, offsetCount, rowsPerPage) {


  return async () => {
    dispatch(buyNumberSlice.actions.startLoading());
    try {
      if (states == "All") {
        const response = await axios.get(`/numbers?type=${type}&country=${country}&country_iso=${country}&limit=${rowsPerPage}&offset=${offsetCount}`);
        dispatch(buyNumberSlice.actions.getNumberSuccess(response.data));
      } else {
        const response = await axios.get(`/numbers?type=${type}&country=${country}&country_iso=${country}&limit=${rowsPerPage}&stateName=${states}&offset=${offsetCount}`);
        dispatch(buyNumberSlice.actions.getNumberSuccess(response.data));
      }



    } catch (error) {
      dispatch(buyNumberSlice.actions.hasError(error));

    }
  };
}

export function buySingleNumber(phoneNumber) {

  const data = { "number": phoneNumber }
  return async () => {
    dispatch(buyNumberSlice.actions.startLoading());
    try {
      const response = await axios.post(`/buyNumber`, data);
      dispatch(buyNumberSlice.actions.buySingleNumberSuccess(response.data));
      console.log('buy number redux response', response.data.status)
      // console.log('buy number redux response data', response.data)
      if (response.data.status === true) {
        // dispatch(showSnackbar('Hurray.. Number Successfully Purchased'));
        dispatch(incrementshowsnackbarslice(true))
        dispatch(showSnackbar('Number has been selected'));
        console.log('buy number redux response data', response.data)
        dispatch(incrementSelectedButton(false))
        localStorage.setItem("Loadernumber", false)

        localStorage.setItem('numberselectedcheck', 'Number has been selected')
      } else {
        dispatch(incrementshowsnackbaroopsslice(true))
        dispatch(incrementshowsnackbarslice(false))
        dispatch(showSnackbar('Oops.. Number selected Failed'));
        dispatch(incrementSelectedButton(false))
      }

    } catch (error) {
      dispatch(buyNumberSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Number selected Failed'));
    }
  };
}