import { createSlice } from '@reduxjs/toolkit'

export const PowerdialerStatusSlice = createSlice({
    name: 'created_by',
    initialState: {
        value: 'All',
    },
    reducers: {
        incrementpowerstatus: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementpowerstatus } = PowerdialerStatusSlice.actions

export default PowerdialerStatusSlice.reducer