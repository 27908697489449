import { 
    Box,
    Typography,
    Tooltip,
    IconButton
    
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {showSnackbar} from '../../../redux/slices/snackbar'

// components
import Iconify from '../../Iconify';

export default function Copy({text}){

    // console.log('copy copy')

    const dispatch = useDispatch();

    const copy = (textCopy) =>{
        dispatch(showSnackbar('Number Copied to Clipboard'));
        navigator.clipboard.writeText(textCopy)
   }

    return(
        
        <Tooltip title="copy to clipboard" >
            <IconButton size='small' className='inbox-btn-small' onClick={() =>  copy(text)}>
                <Iconify icon='clarity:copy-line'  />
            </IconButton>
        </Tooltip> 
                        
    )
}