import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: '',
}

export const isTokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    incrementToken: (state , {payload}) => {
      console.log("payload ", payload)
      state.value = payload
      localStorage.setItem('redux_token', payload)
      
   
    }
  },
})

// Action creators are generated for each case reducer function
export const { incrementToken } = isTokenSlice.actions

export default isTokenSlice.reducer