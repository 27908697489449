
import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from '../../../utils/axios';

//
import { dispatch } from '../../store';

import {showSnackbar} from '../snackbar'

const userID = localStorage.getItem('userID')
const authID = localStorage.getItem('authID')


const initialState= {
    isLoading: false,
    error: null,
    isCreateLoading: false,
    isCreateLoadingText: '',
    contactGroupData: {},
    createGroupResponse: {},
    updateGroupResponse: {},
    deleteGroupResponse: {},
    groupData: {},
}

const contactsGroupSlice = createSlice({
    name: 'contactsGroup',
    initialState,

    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // START LOADING
        createLoading(state) {
            state.isCreateLoading = true;
            state.isCreateLoadingText = ''
        },

        // START LOADING
        createLoadingSuccess(state) {
            state.isCreateLoading = false;
            state.isCreateLoadingText = 'Success'
        },

         // START LOADING
         createLoadingFailed(state) {
            state.isCreateLoading = false;
            state.isCreateLoadingText = 'Failed'
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            
        },
        
        // get group
        getGroupsSuccess(state, action) {
            state.contactGroupData = action.payload;
            state.isLoading = false;
        },

        // post group
        postGroupsSuccess(state, action) {
            state.createGroupResponse = action.payload;
            state.isLoading = false;
        },

        // put group
        putGroupSuccess(state, action) {
            state.updateGroupResponse = action.payload;
            state.isLoading = false;
        },

        // put group
        deleteGroupSuccess(state, action) {
            state.deleteGroupResponse = action.payload;
            state.isLoading = false;
        },

        // get group
        getGroupSuccess(state, action) {
            state.groupData = action.payload;
            state.isLoading = false;
        },
     
    }
})



export const selectAllGroupData = (state) => state.contactsGroup;

export default contactsGroupSlice.reducer


// Actions

export function getAllGroups(offsetCount, rowsPerPage) {
    return async () => {
      dispatch(contactsGroupSlice.actions.startLoading());
      try {
        const response = await axios.get(`/groups?limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`);
          dispatch(contactsGroupSlice.actions.getGroupsSuccess(response.data));
          console.log('get All Contacts Success', response)
 
      } catch (error) {
        dispatch(contactsGroupSlice.actions.hasError(error));
      }
    };
  }

  export function getGroup(id) {
    return async () => {
      dispatch(contactsGroupSlice.actions.startLoading());
      try {
        const response = await axios.get(`/groups/${id}`);
          dispatch(contactsGroupSlice.actions.getGroupSuccess(response.data));
          console.log('get group Success', response)
 
      } catch (error) {
        dispatch(contactsGroupSlice.actions.hasError(error));
      }
    };
  }

  
export function createGroup(data) {
    return async () => {
      dispatch(contactsGroupSlice.actions.createLoading());
      try {
        const response = await axios.post(`/groups`, data);
          dispatch(contactsGroupSlice.actions.postGroupsSuccess(response.data));
          console.log('post group Success', response)

          if(response.data.status === 'success'){
            dispatch(showSnackbar('Hurray.. Group Successfully Created'));
            dispatch(contactsGroupSlice.actions.createLoadingSuccess());
           
          }else{
            dispatch(showSnackbar('Oops.. Failed'));
            dispatch(contactsGroupSlice.actions.createLoadingFailed());
          } 
      } catch (error) {
        dispatch(contactsGroupSlice.actions.hasError(error));
        dispatch(showSnackbar('Oops.. Failed'));
        dispatch(contactsGroupSlice.actions.createLoadingFailed());
      }
    };
  }

  export function editGroup(id, data) {
    return async () => {
      dispatch(contactsGroupSlice.actions.createLoading());
      try {
        const response = await axios.put(`/groups/${id}`, data);
          dispatch(contactsGroupSlice.actions.putGroupSuccess(response.data));
          console.log('put group Success', response)

          if(response.data.status === true){
            dispatch(showSnackbar('Hurray.. Group Successfully Updated'));
            dispatch(contactsGroupSlice.actions.createLoadingSuccess());
          }else{
            dispatch(showSnackbar('Oops.. Failed'));
            dispatch(contactsGroupSlice.actions.createLoadingFailed());
          }

 
      } catch (error) {
        dispatch(contactsGroupSlice.actions.hasError(error));
        dispatch(showSnackbar('Oops.. Failed'));
        dispatch(contactsGroupSlice.actions.createLoadingFailed());
      }
    };
  }


  export function deleteGroup(id) {
    return async () => {
      dispatch(contactsGroupSlice.actions.startLoading());
      try {
        const response = await axios.delete(`/groups/${id}`);
          dispatch(contactsGroupSlice.actions.deleteGroupSuccess(response.data));
          if(response.data.status === true){
            dispatch(showSnackbar('Hurray.. Successfully Deleted'));
            dispatch(getAllGroups(0,10))
          }else{
            dispatch(showSnackbar('Oops.. Delete Failed'));
          }
      } catch (error) {
          dispatch(contactsGroupSlice.actions.hasError(error));
          dispatch(showSnackbar('Oops.. Delete Failed'));
      }
    };
  }
