import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    value : 0
}

export const makeCallSwitchSlice = createSlice({
    name: "makeCallSwitch",
    initialState,
    reducers: {
        incrementMakeCallSwitch : (state) => {
            state.value += 1
        }
    }
});

export const {incrementMakeCallSwitch} = makeCallSwitchSlice.actions
export default makeCallSwitchSlice.reducer