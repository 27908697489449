import { createSlice } from '@reduxjs/toolkit'

export const showsnackbarSlice = createSlice({
    name: 'show_snackbar',
    initialState: {
        value: '',
    },
    reducers: {
        incrementshowsnackbarslice: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementshowsnackbarslice } = showsnackbarSlice.actions

export default showsnackbarSlice.reducer