import { createSlice } from '@reduxjs/toolkit'

export const whatsappnumberidSlice = createSlice({
    name: 'whatsapp_numberid',
    initialState: {
        value: '',
    },
    reducers: {
        incrementwhatsappnumberid: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementwhatsappnumberid } = whatsappnumberidSlice.actions

export default whatsappnumberidSlice.reducer