import { createSlice } from '@reduxjs/toolkit'

export const Inboxuseridslice = createSlice({
    name: 'inbox_user_id',
    initialState: {
        value: [],
        validate: false
    },
    reducers: {
        incrementinboxuserid: (state, { payload }) => {
            state.value = [...payload]
        },
        incrementinboxuseridValidate: (state, { payload }) => {
            state.validate = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementinboxuserid, incrementinboxuseridValidate } = Inboxuseridslice.actions

export default Inboxuseridslice.reducer