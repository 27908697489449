import { createSlice } from '@reduxjs/toolkit'

export const facebookacessSlice = createSlice({
    name: 'facebook_access',
    initialState: {
        value: '',
        validate: false,
    },
    reducers: {
        incrementfacebookacess: (state, { payload }) => {
            state.value = payload
        },
        incrementfacebookacessValidate: (state, { payload }) => {
            state.validate = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementfacebookacess, incrementfacebookacessValidate } = facebookacessSlice.actions

export default facebookacessSlice.reducer