import PropTypes from 'prop-types';
// @mui
import { Box, Tooltip } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// guards
import RoleBasedGuard from '../../../guards/RoleBasedGuard';
//
import Iconify from '../../Iconify';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';
import { useLocation, useParams } from 'react-router';

// ----------------------------------------------------------------------

NavItem.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  depth: PropTypes.number,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    caption: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default function NavItem({ item, depth, active, open, isCollapse, ...other }) {
  const { translate } = useLocales();

  const { title, icon, icons, voicenumber, info, whatsappphoneid, whatsappname, children, disabled, caption, whatsAppNumber, roles } = item;
  console.log("items", item)
  const location = useLocation()

  const { phoneNumberId, number } = useParams()
  console.log('itemsnumber', number, voicenumber)

  if (whatsappphoneid === phoneNumberId) {
    localStorage.setItem('whatsappNumber', whatsAppNumber)
    localStorage.setItem('whatsappNumberid', phoneNumberId)
    localStorage.setItem('whatsappName', whatsappname)
  }
  if (voicenumber == number) {
    localStorage.setItem('assignedNumbervoice', number)
  }
  // console.log("itemsurl", phoneNumberId)
  // const filterdata = whatsappphoneid.filter((item) => item == phoneNumberId)
  // console.log("filterdata", filterdata)

  const renderContent = (
    <>
      <ListItemStyle depth={depth} active={active} disabled={disabled} {...other}>
        {icon && <ListItemIconStyle>  {icon}</ListItemIconStyle>}
        {icons ? <>
          {icons === 'bi bi-folder-plus' ? <>
            <i className={icons} style={{
              marginRight: "10%",
              color: active ? '#0c53b7' : "#6e7d8a"
            }}></i>
          </> : <>
            <i className={`bi bi-${icons}`} style={{
              marginRight: "10%",
              color: active ? '#0c53b7' : "#6e7d8a"
            }}></i>
          </>}






        </> : <>
          {depth !== 1 && <DotIcon active={active && depth !== 1} />}
        </>}



        <ListItemTextStyle
          isCollapse={isCollapse}
          primary={translate(title)}
          secondary={
            caption && (
              <Tooltip title={translate(caption)} placement="top-start">
                <span>{translate(caption)}</span>
              </Tooltip>
            )
          }
          primaryTypographyProps={{
            noWrap: true,
            variant: active ? 'subtitle2' : 'body2',
          }}
          style={{
            color: active ? '#0c53b7' : "#6e7d8a"
          }}
          secondaryTypographyProps={{
            noWrap: true,
            variant: 'caption',
          }}
        />

        {!isCollapse && (
          <>
            {info && (
              <Box component="span" sx={{ lineHeight: 0 }}>
                {info}
              </Box>
            )}

            {!!children && (
              <Iconify
                icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
              />
            )}
          </>
        )}

      </ListItemStyle>

    </>
  );

  return <RoleBasedGuard roles={roles}>{renderContent}

  </RoleBasedGuard>;
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}
