export const vikalp = [
    {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    },
    {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    }, {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    }, {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    }, {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    }, {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    },
    {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    }, {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    }, {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    }, {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    }, {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    }, {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    }, {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    }, {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    }, {
        transaction_id: "860773000000340858",
        reference_id: "INV-LLP0158",
        date: "2022-09-20",
        type: "payment",
        status: "success",
        amount: 21240.00
    },

];

