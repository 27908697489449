import { useDispatch, useSelector } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { clearSnackbar, selectSnackbar } from "../redux/slices/snackbar";
import Iconify from './Iconify';

export default function SuccessSnackbar() {

  
  const dispatch = useDispatch();

  const { message, successSnackbarOpen } = useSelector(
    state => state.snackbar
  );

  const handleClose = () => {
    
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={successSnackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar">
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <Iconify icon={'ic:outline-close'} />
        </IconButton>
      ]}
    />
  );
}