import { createSlice } from '@reduxjs/toolkit'

export const fileuploadpathSlice = createSlice({
    name: 'upload_path',
    initialState: {
        value: {},
    },
    reducers: {
        incrementUploadpath: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementUploadpath } = fileuploadpathSlice.actions

export default fileuploadpathSlice.reducer