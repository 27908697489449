import { createSlice } from '@reduxjs/toolkit'

export const iconpickerslice = createSlice({
    name: 'icon_picker',
    initialState: {
        value: '',
    },
    reducers: {
        incrementiconpicker: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementiconpicker } = iconpickerslice.actions

export default iconpickerslice.reducer